@charset "UTF-8";
/* >480 - смартфоны */
/* >768 - планшеты */
/* >1024 - нетбуки, ноутбуки */
/* >1200 - десктоп */
/* >1600 - десктоп */
.gallery-sortable,
.gallery-sortable__row,
.gallery-sortable__item {
  position: relative;
}

.gallery-sortable__item picture-loader-trigger {
  cursor: pointer;
}

.gallery-sortable {
  text-align: center;
}

.gallery-sortable__row.as-sortable-drag {
  position: absolute;
}
.gallery-sortable__row.as-sortable-drag .gallery-sortable__item {
  flex-basis: 100%;
  max-width: 100%;
}

.gallery-sortable__item {
  /* display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle; */
  display: inline-block;
}
.gallery-sortable__item:hover .gallery-sortable__visibility-hover {
  display: block;
}

.gallery-sortable__visibility-hover {
  display: none;
}

.gallery-sortable__item-handle {
  position: absolute;
  top: 10px;
  left: 18px;
}

.gallery-remove {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 999;
}

.lp-galley-item__inner {
  padding: 8px;
  box-sizing: border-box;
}

/********* gallery puzzles ********/
.gallery-puzzles .as-sortable-dragging {
  width: 100px !important;
  height: 100px !important;
}

.gallery-puzzles .gallery-remove {
  top: 20px;
  right: 20px;
}

.gallery-puzzles .gallery-sortable__item-handle {
  top: 20px;
  left: 28px;
}

.gallery-sortable__puzzles-item {
  float: none;
  /* width: 100%; */
  padding: 20px;
  box-sizing: border-box;
}
.gallery-sortable__puzzles-item img {
  vertical-align: middle;
}
.gallery-sortable__puzzles-item .picture-loader-trigger-image-inner {
  /* height: 256px; */
}

/* .gallery-sortable__puzzles-item:first-child,
.gallery-sortable__puzzles-item:nth-child(10n) {
    padding: 20px;
    width: 50%;
    box-sizing: border-box;
}

.gallery-sortable__puzzles-item:nth-child(8),
.gallery-sortable__puzzles-item:nth-child(10n + 17) {
    width: 50%;
    float: right;
    padding: 40px;
    box-sizing: border-box;
} */
.gallery-sortable__puzzles-row {
  overflow: hidden;
  text-align: center;
}

@media (min-width: 48em) {
  .gallery-sortable__puzzles-wrap {
    margin-left: -20px;
    margin-right: -20px;
  }
  .gallery-sortable__puzzles-item {
    float: left;
    width: 25%;
    padding: 20px;
    box-sizing: border-box;
  }
  .gallery-sortable__puzzles-item img {
    max-height: 256px;
    height: auto;
  }
  .gallery-sortable__puzzles-item:first-child,
  .gallery-sortable__puzzles-item:nth-child(11n),
  .gallery-sortable__puzzles-item:nth-child(8),
  .gallery-sortable__puzzles-item:nth-child(10n+17) {
    width: 50%;
  }
  .gallery-sortable__puzzles-item:first-child img,
  .gallery-sortable__puzzles-item:nth-child(11n) img,
  .gallery-sortable__puzzles-item:nth-child(8) img,
  .gallery-sortable__puzzles-item:nth-child(10n+17) img {
    max-height: 552px;
  }
  .gallery-sortable__puzzles-item:nth-child(8),
  .gallery-sortable__puzzles-item:nth-child(10n+17) {
    float: right;
  }
}
/********************************   */
.lp-block-gallery-with-text .picture-loader-trigger-image-inner {
  display: block;
}

.lp-block-gallery-with-text__inner {
  position: relative;
  padding: 8px;
  box-sizing: border-box;
}

.lp-block-gallery-with-text__text-block {
  visibility: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  padding: 20px;
  display: block;
}

.lp-block-gallery-with-text__subtitle {
  font-size: 16px;
  line-height: 22px;
}

.lp-block-gallery-with-text__text {
  font-size: 14px;
  line-height: 20px;
}

.lp-block-gallery-with-text__image-wrap {
  display: inline-block;
  position: relative;
}

.lp-block-gallery-with-text__image-wrap:hover .lp-block-gallery-with-text__text-block {
  visibility: visible;
}

.lp-block-gallery-with-text picture-loader-trigger {
  width: 100%;
}

@media (min-width: 48em) {
  .lp-block-gallery-with-text__subtitle {
    font-size: 18px;
    line-height: 24px;
  }
  .lp-block-gallery-with-text__text {
    font-size: 16px;
    line-height: 28px;
  }
}