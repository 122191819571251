@charset "UTF-8";
/* >480 - смартфоны */
/* >768 - планшеты */
/* >1024 - нетбуки, ноутбуки */
/* >1200 - десктоп */
/* >1600 - десктоп */
.image-block-picture-trigger-image {
  width: 278px;
  height: 160px;
}

.addition-header {
  font-size: 33px;
  line-height: 1.04;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 32px;
}

.addition-text {
  line-height: 1.3;
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
}

@media (min-width: 48em) {
  .addition-header {
    margin-top: 30px;
    font-size: 43px;
    line-height: 1.25;
    min-height: 0;
    min-height: initial;
  }
  .addition-text {
    line-height: 1.25;
    font-size: 20px;
    margin-bottom: 40px;
  }
}
@media (min-width: 75em) {
  .addition-header {
    margin-top: 0;
    font-size: 52px;
    line-height: 1.1;
  }
  .addition-text {
    font-size: 24px;
    line-height: 1.3;
    margin-bottom: 60px;
  }
}
/****************** image-carousel **********************/
.image-carousel__item.slick-slide {
  margin-bottom: 26px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

.image-carousel .slick-prev {
  left: -50px;
}

.image-carousel .slick-next {
  right: -50px;
}

.image-carousel-alt .slick-next::before,
.image-carousel-alt .slick-prev::before {
  color: #fff;
}

.slick-arrow--inner .slick-arrow,
.image-carousel-alt .slick-arrow {
  width: 36px;
  height: 110px;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
  transition: opacity linear 0.5s;
  color: #fff;
}

.slick-arrow--inner:hover .slick-arrow,
.image-carousel-alt .image-carousel-alt__carousel:hover .slick-arrow {
  opacity: 1;
  transition: opacity linear 0.5s;
}

.slick-dots--inner .slick-dots li button {
  background-color: #fff;
  border: 3px solid rgba(0, 0, 0, 0.8);
  opacity: 0.7;
  padding: 3px;
}

.slick-dots--inner .slick-dots li.slick-active button {
  opacity: 1;
}

.image-carousel-alt .image-carousel-alt__carousel .slick-next {
  right: 0;
}

.image-carousel-alt .image-carousel-alt__carousel .slick-prev {
  left: 0;
}

.image-carousel-alt .slick-dots {
  bottom: 35px;
}

/****************************************************   */
.lp-block-image-full-width__block img,
.lp-block-image-full-width__block .picture-loader-trigger,
.lp-block-image-full-width__block picture-loader-trigger {
  width: 100%;
}
.lp-block-image-full-width__block .picture-loader-trigger-image-inner {
  display: block;
}

/***************************************************   */
.text-image-countdown__content {
  padding-right: 0;
}
.text-image-countdown__picture-wrap {
  margin-bottom: 25px;
}
.text-image-countdown .text-image-countdown__title {
  font-weight: bold;
}
.text-image-countdown .lp-countdown {
  text-align: left;
  display: flex;
}
.text-image-countdown__text {
  margin-bottom: 12px;
}
.text-image-countdown__countdown {
  margin-bottom: 20px;
}
.text-image-countdown .wSeconds .lp-countdown__item {
  width: 25%;
  box-sizing: border-box;
  max-width: 75px;
  min-width: 69px;
}
.text-image-countdown .lp-countdown__item-part {
  /* width: 50%; */
  box-sizing: border-box;
  font-size: 30px;
  height: 65px;
  width: calc(50% - 1px);
}
.text-image-countdown .lp-countdown__item-label {
  font-size: 16px;
}

.text-image-countdown--reverse .text-image-countdown__content {
  padding-left: 0;
  margin-bottom: 25px;
}

@media (min-width: 48em) {
  .text-image-countdown__content {
    padding-right: 10px;
  }
  .text-image-countdown__picture-wrap {
    margin-bottom: 0;
  }
  .text-image-countdown .lp-countdown {
    margin-bottom: 0;
    justify-content: flex-start;
  }
  .text-image-countdown__text {
    margin-bottom: 24px;
  }
  .text-image-countdown .wSeconds .lp-countdown__item {
    max-width: 112px;
    min-width: 69px;
  }
  .text-image-countdown .lp-countdown__item-part {
    width: calc(50% - 4px);
    font-size: 40px;
  }
  .text-image-countdown--reverse .text-image-countdown__content {
    padding-left: 10px;
    margin-bottom: 0;
  }
}
@media (min-width: 64em) {
  .text-image-countdown {
    /* .lp-countdown__item-part {
        font-size: 64px;
    } */
  }
}
/*****************************************   */
.image-background-with-text .lp-h {
  margin-bottom: 24px;
}
.image-background-with-text__content {
  padding: 80px 40px;
}

/*** text-image-full-height ***/
.text-image-full-height__inner {
  flex-grow: 1;
}
.text-image-full-height__content, .text-image-full-height__picture-wrap {
  z-index: 1;
  width: 100%;
  flex-basis: 100%;
  max-width: 100%;
  box-sizing: border-box;
}
.text-image-full-height picture-loader-trigger {
  display: block;
}
.text-image-full-height__content {
  padding: 20px 32px;
}
.text-image-full-height__content-inner.color-scheme__bg--alt {
  padding: 30px;
}
.text-image-full-height__picture-wrap img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 220px;
}
.text-image-full-height__buttons-wrap {
  margin-top: 10px;
}

@media (min-width: 48em) {
  .text-image-full-height__inner-wrap {
    display: flex;
    min-height: 100vh;
    min-height: var(--min-full-height, 100vh);
    flex-direction: column;
  }
  .text-image-full-height__inner {
    width: 100%;
    box-sizing: border-box;
    position: relative;
  }
  .text-image-full-height__content, .text-image-full-height__picture-wrap {
    position: absolute;
    z-index: 1;
    width: 50%;
    box-sizing: border-box;
    top: 0;
    bottom: 0;
  }
  .text-image-full-height__picture-wrap {
    left: 0;
    right: auto;
  }
  .text-image-full-height__picture-wrap picture-loader-trigger,
  .text-image-full-height__picture-wrap picture-loader-trigger span {
    display: block;
    width: 100%;
    height: 100%;
  }
  .text-image-full-height__picture-wrap img {
    -o-object-position: left center;
       object-position: left center;
    height: 100vh;
    height: var(--min-full-height, 100vh);
    width: 100%;
  }
  .text-image-full-height__content {
    left: auto;
    right: 0;
    padding: 20px 70px;
  }
  .text-image-full-height__buttons-wrap {
    margin-top: 38px;
  }
  .text-image-full-height--reverse .text-image-full-height__content {
    right: auto;
    left: 0;
  }
  .text-image-full-height--reverse .text-image-full-height__picture-wrap {
    left: auto;
    right: 0;
  }
}
/***************************   */