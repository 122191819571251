.carousel-nav--inside-landing .carousel-inner {
  border: 1px solid #f3f3f3;
  padding-top: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
}
.carousel-nav--inside-landing:hover .carousel-arrow-prev--inside-landing,
.carousel-nav--inside-landing:hover .carousel-arrow-next--inside-landing {
  opacity: 1;
  transition: opacity linear 0.5s;
}

.carousel-arrow-prev--inside-landing,
.carousel-arrow-next--inside-landing {
  width: 36px;
  max-height: 110px;
  height: 100%;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
  color: #fff;
}

.carousel-dots-item--landing {
  width: 10px;
  height: 10px;
  box-shadow: none;
  background-color: currentcolor;
  opacity: 0.2;
  margin: 0 5px;
}

.carousel-dots-selected .carousel-dots-item--landing {
  background-color: #000;
  opacity: 1;
}

.carousel-dots--landing + .carousel-nav .carousel-arrow-prev--inside-landing,
.carousel-dots--landing + .carousel-nav .carousel-arrow-next--inside-landing {
  margin-top: -10px;
}

.slick-dots {
  bottom: auto;
  top: 100%;
}