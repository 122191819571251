a.social {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  overflow: hidden;
  font-size: 16px;
  margin: 5px;
  -webkit-text-decoration: none;
  text-decoration: none;
  cursor: pointer;
}
a.social .social__name {
  display: none;
  font-size: 13px;
}
a.social:hover {
  color: #fff;
  -webkit-text-decoration: none;
  text-decoration: none;
}
a.social--call {
  background-color: rgb(5, 90, 152);
}
a.social--call:hover {
  background-color: #0786e2;
}
a.social--vkontakte {
  background-color: rgb(59, 74, 95);
}
a.social--vkontakte:hover {
  background-color: #586f8e;
}
a.social--whatsapp {
  background-color: rgb(0, 230, 118);
}
a.social--whatsapp:hover {
  background-color: #34ff9c;
}
a.social--viber {
  background-color: rgb(123, 66, 169);
}
a.social--viber:hover {
  background-color: #a070c7;
}
a.social--facebook {
  background-color: rgb(63, 92, 150);
}
a.social--facebook:hover {
  background-color: #6482be;
}
a.social--youtube {
  background-color: hsl(0, 100%, 50%);
}
a.social--youtube:hover {
  background-color: #ff4d4d;
}
a.social--twitter {
  background-color: rgb(86, 171, 238);
}
a.social--twitter:hover {
  background-color: #9ccef5;
}
a.social--instagram {
  background-color: #d10869;
}
a.social--instagram:hover {
  background-color: #f72e8f;
}
a.social--telegram {
  background-color: #35ace1;
}
a.social--telegram:hover {
  background-color: #78c7eb;
}
a.social--odnoklassniki {
  background-color: #ee8208;
}
a.social--odnoklassniki:hover {
  background-color: #f9a74a;
}