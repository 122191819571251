.progressbar {
  position: relative;
  margin-top: 36px;
}
.progressbar__track, .progressbar__track--passed {
  font-size: 0;
  line-height: 0;
  height: 8px;
  border-radius: 4px;
}
.progressbar__marker {
  position: absolute;
  bottom: 100%;
  margin-left: -16px;
}
.progressbar__step {
  margin-top: 16px;
}