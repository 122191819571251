@charset "UTF-8";
/* >480 - смартфоны */
/* >768 - планшеты */
/* >1024 - нетбуки, ноутбуки */
/* >1200 - десктоп */
/* >1600 - десктоп */
.error-landing {
  width: 100%;
  padding-top: 20px;
  box-sizing: border-box;
  font-size: 13px;
  font-family: Tahoma, "Helvetica Neue", Helvetica, Geneva, Arial, sans-serif;
  line-height: 1.5;
  flex-grow: 1;
}

.err-container {
  margin-top: 50px;
  margin-bottom: 50px;
}

.err-txt {
  font-size: 36px;
  font-weight: bold;
}

.err-reasons {
  text-align: center;
  font-size: 16px;
}

.err-reasons-title {
  font-size: 24px;
}

.err-reasons-list {
  list-style: none;
  padding: 0;
  font-size: 21px;
  margin-bottom: 35px;
}

.err-code {
  color: black;
  font-size: 140px;
  position: relative;
  /* width: 330px; */
  margin: 0 auto;
  line-height: 1;
  margin-top: -30px;
}

.err-image {
  margin: 0 auto;
  display: block;
}

@media (min-width: 48em) {
  .err-code {
    margin-top: -30px;
    font-size: 192px;
  }
}