@charset "UTF-8";
/* >480 - смартфоны */
/* >768 - планшеты */
/* >1024 - нетбуки, ноутбуки */
/* >1200 - десктоп */
/* >1600 - десктоп */
.lp-block-team-three-columns-carousel__row-wrap,
.lp-block-team-three-columns__row-wrap,
.lp-block-team-three-columns-rounded__row-wrap {
  margin-left: -10px;
  margin-right: -10px;
}
.lp-block-team-three-columns-carousel__row,
.lp-block-team-three-columns__row,
.lp-block-team-three-columns-rounded__row {
  /* margin-left: -$threeColumnsItemPadding;
  margin-right: -$threeColumnsItemPadding; */
}
.lp-block-team-three-columns-carousel__item, .lp-block-team-three-columns-carousel__item--header,
.lp-block-team-three-columns__item,
.lp-block-team-three-columns__item--header,
.lp-block-team-three-columns-rounded__item,
.lp-block-team-three-columns-rounded__item--header {
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}
.lp-block-team-three-columns-carousel__item,
.lp-block-team-three-columns__item,
.lp-block-team-three-columns-rounded__item {
  padding-bottom: 10px;
}
.lp-block-team-three-columns-carousel__content,
.lp-block-team-three-columns__content,
.lp-block-team-three-columns-rounded__content {
  padding: 24px;
  text-align: center;
  word-break: break-word;
}
.lp-block-team-three-columns-carousel__text,
.lp-block-team-three-columns__text,
.lp-block-team-three-columns-rounded__text {
  margin-top: 8px;
}

.lp-block-team-three-columns-carousel__row {
  text-align: center;
}

.lp-block-team-three-columns-rounded__picture img {
  width: 140px;
  height: 140px;
  overflow: hidden;
  /* border-radius: 50%; */
}

.lp-block-team-details__subheader,
.lp-block-team-details-reverse__subheader {
  margin-top: 4px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
}
.lp-block-team-details__content,
.lp-block-team-details-reverse__content {
  margin-top: 18px;
  line-height: 1.5;
  margin-bottom: 23px;
}
.lp-block-team-details__col-data,
.lp-block-team-details-reverse__col-data {
  padding-top: 24px;
}

.lp-block-team-details-reverse__header,
.lp-block-team-details__header {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 4px;
}

@media (min-width: 48em) {
  .lp-block-team-details-reverse__header,
  .lp-block-team-details__header {
    font-size: 24px;
    line-height: 1.33;
  }
  .lp-block-team-details-reverse__subheader,
  .lp-block-team-details__subheader {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.56;
  }
  .lp-block-team-details__col-data {
    padding-top: 0;
    padding-left: 40px;
  }
  .lp-block-team-details-reverse__col-data {
    padding-top: 0;
    padding-right: 40px;
  }
}