@charset "UTF-8";
/* >480 - смартфоны */
/* >768 - планшеты */
/* >1024 - нетбуки, ноутбуки */
/* >1200 - десктоп */
/* >1600 - десктоп */
.lp-header {
  position: relative;
}

.lp-header__container {
  min-height: 59px;
}

.lp-header__container--strut {
  min-height: 83px;
}

.lp-header-static-block {
  display: inline-block;
  vertical-align: middle;
}

.lp-logo {
  display: block;
}

.lp-header__email {
  font-size: 13px;
  margin: 5px 0;
  padding-right: 7px;
}

.lp-header__phone {
  font-size: 18px;
  margin: 5px 0;
  padding-right: 7px;
}

.lp-header__phone-arrow::after {
  font-size: 13px;
}

.lp-block-header.transformer-scroll-over {
  z-index: 50;
}

.lp-header-logo {
  padding-left: 48px;
  padding-right: 48px;
  text-align: center;
  display: inline-block;
  max-width: 400px;
}

.transformer-scroll-default .lp-header.color-scheme__bg-opacity {
  background: transparent none;
}

.transformer-scroll-over .lp-header.color-scheme__bg-opacity {
  transition: background-color linear 1s;
}

.lp-header__callback {
  padding-left: 8px;
  padding-right: 8px;
}

.lp-header__contacts {
  margin-left: 10px;
}

.lp-header .lp-header__phone--mobile {
  color: currentcolor;
}

.lp-menu-header__item {
  position: relative;
}

.lp-menu-header__submenu {
  border-width: 1px 0;
  border-style: solid;
  list-style-type: none;
  margin: 0;
  /* text-align: left; */
  border-radius: 0;
  padding-left: 0;
  margin-top: 25px;
  padding-bottom: 0;
}

.lp-menu-header__submenu--hidden {
  display: none;
}

.lp-menu-header__submenu-item {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 90%;
}

.lp-menu-header__submenu-link {
  -webkit-text-decoration: none;
  text-decoration: none;
  white-space: nowrap;
}
.lp-menu-header__submenu-link:hover {
  -webkit-text-decoration: underline;
  text-decoration: underline;
}

.lp-menu-header__submenu.show-submenu {
  display: block;
}

.lp-header__phone-col {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 48px;
  right: 18px;
}

.lp-header__phone-col--left {
  right: auto;
  left: 0;
}

.lp-header__phone--mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.lp-menu-header__mobile-padding-for-panel {
  padding-bottom: 20px;
}

.lp-block-header-burger--with-text .lp-menu-header__label {
  position: static;
  width: 22px;
  height: 19px;
  padding: 0;
  padding-top: 3px;
}
.lp-block-header-burger--with-text .lp-menu-header__burger-container {
  position: static;
  width: auto;
}
.lp-block-header-burger--with-text .lp-menu-header__label-obj {
  position: static;
  transform: none;
}
.lp-block-header-burger--with-text .lp-menu-header__burger-inner {
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.lp-block-header-burger--with-text .lp-header__text {
  white-space: normal;
  padding-left: 15px;
}

@media (min-width: 64em) {
  /* .blocks-constructor-container.block-type-header:hover {
      z-index: 155;
  } */
  .lp-menu-header__submenu--abs {
    border: 0;
    list-style-type: none;
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
    margin: 0;
    text-align: left;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.07), 0 0 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    padding: 6px 0;
  }
  .lp-menu-header__submenu--abs + .lp-menu-header__submenu-tile::before {
    opacity: 0;
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    box-shadow: -1px -1px 2px 0 rgba(0, 0, 0, 0.1);
    z-index: 1;
    left: 50%;
    bottom: -8px;
    transform: translateX(-7px) rotate(45deg);
  }
  .lp-menu-header__item:hover {
    z-index: 10;
  }
  .lp-menu-header__item:hover > .lp-menu-header__submenu--abs + .lp-menu-header__submenu-tile::before {
    opacity: 1;
  }
  .lp-menu-header__item:hover .lp-menu-header__submenu--abs {
    display: block;
  }
  .lp-menu-header__submenu-item--abs {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .lp-header-logo {
    padding-left: 0;
    padding-right: 0;
    text-align: left;
  }
  .lp-header-logo img {
    max-height: none;
  }
  /* .lp-block-header-center .lp-menu-header, */
  .lp-block-header-burger-right .lp-header-logo,
  .lp-block-header-burger .lp-menu-header__burger-container {
    justify-content: flex-start;
    text-align: left;
  }
  .lp-block-header-center .lp-menu-header__item:first-child {
    padding-left: 0 !important;
  }
  .lp-block-header-burger .lp-header--container {
    justify-content: flex-start;
  }
  .lp-menu--to-right .lp-menu-header,
  .lp-menu--to-right .lp-menu-header-container {
    justify-content: flex-end !important;
  }
  .lp-header-menu {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .lp-menu-header__mobile-padding-for-panel {
    padding-bottom: 0;
  }
}