@charset "UTF-8";
/* >480 - смартфоны */
/* >768 - планшеты */
/* >1024 - нетбуки, ноутбуки */
/* >1200 - десктоп */
/* >1600 - десктоп */
/* $min-width: 1200px; */
.lp-container {
  max-width: 1200px;
  margin: 0 auto;
}