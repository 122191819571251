@charset "UTF-8";
/* >480 - смартфоны */
/* >768 - планшеты */
/* >1024 - нетбуки, ноутбуки */
/* >1200 - десктоп */
/* >1600 - десктоп */
/******** Spinbox ************/
.details-spinbox-block {
  position: relative;
  width: 8rem !important;
}

.spinbox-input-wrap {
  padding-right: 8px;
}

.details-spinbox-block .spinbox-more::before {
  content: "+";
  font-size: 20px;
  margin: 0;
}

.details-spinbox-block .spinbox-less::before {
  content: "-";
  font-size: 20px;
  margin: 0;
}

.details-spinbox-block .spinbox-input-wrap input {
  border-radius: 0;
  padding: 0.625rem 2.1875rem;
  text-align: center;
  background: #f7f7f7;
  border: none;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  outline: none;
}

.details-spinbox-block .spinbox-more {
  right: 0;
  top: 0;
  bottom: 0;
  background: #f7f7f7;
  color: #000;
  width: 20px;
}

.details-spinbox-block .spinbox-less {
  left: 0;
  top: 0;
  bottom: 0;
  background: #f7f7f7;
  color: #000;
  width: 20px;
}

.details-spinbox-block .spinbox-more,
.details-spinbox-block .spinbox-less {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  /* color: currentColor; */
}

.details-param-value-weight {
  font-size: 14px;
}

/****************************   */
/********* Модальное окно ***********/
.lp-product-info__payment-item {
  padding-right: 10px;
}

.lp-product-info__payment-item:last-child {
  padding-right: 0;
}

.lp-product-info__link,
.lp-product-info__link:active,
.lp-product-info__link:visited {
  color: rgb(23, 121, 250);
}

.lp-product-info__sku-wrap,
.lp-product-info__meta-item {
  text-transform: uppercase;
}

.lp-product-info__availability,
.lp-product-info__link,
.lp-product-info__meta,
.lp-product-info__sku-wrap {
  font-size: 14px;
  font-weight: 300;
}

.lp-product-info__sku-value {
  padding-left: 5px;
}

.modal-quickview .gallery-block {
  margin-bottom: 30px;
}

.modal-quickview .details-carousel,
.modal-quickview .details-tabs {
  margin-top: 0;
}

.modal-quickview .lp-products-view-alt__name {
  margin-bottom: 10px;
}

.modal-quickview .gallery-photos-slider-list-horizontal {
  display: flex;
}

.gallery-photos-slider-list-horizontal {
  white-space: nowrap;
  overflow: hidden;
}

.modal-quickview .color-viewer-item,
.modal-quickview .sizes-viewer-block {
  margin-right: 5px;
  margin-bottom: 5px;
}

.modal-quickview .lp-product-info__price {
  font-weight: normal;
  font-size: 24px;
}
.modal-quickview .lp-product-info__price .price-unknown {
  font-size: 15px;
}

.modal-quickview .sizes-viewer-item {
  padding-top: 2px;
  padding-bottom: 2px;
  border: 1px solid rgb(204, 204, 204);
}

.modal-quickview .color-viewer-item.color-viewer-item-selected {
  border: 1px solid black !important;
}

.modal-quickview .sizes-viewer-item.sizes-viewer-item-selected {
  border: 1px solid black !important;
  box-shadow: #000 0 0 0 1px inset;
}

.modal-quickview .color-viewer-item,
.modal-quickview .sizes-viewer-item,
.modal-quickview .sizes-viewer-inner {
  border-radius: 0;
}

.modal-quickview .sizes-viewer-inner {
  padding-left: 15px;
  padding-right: 15px;
  margin: 4px;
}

.modal-quickview figure.gallery-picture {
  margin: 0;
}

.modal-quickview.adv-modal-inner,
.adv-modal-floating .modal-quickview.adv-modal-inner {
  width: 100%;
}

.lp-product-info__payment-item,
.lp-product-info__amount,
.lp-product-info__meta,
.lp-product-info__price-wrap {
  margin-bottom: 15px;
}

.gallery-picture-link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lp-product-info__amount-name,
.lp-product-info__dimensions,
.lp-product-info__unit,
.lp-product-info__sizes .sizes-viewer-header,
.lp-product-info__weight,
.details-sku,
.details-param-name,
.sizes-viewer-header,
.color-viewer-header {
  font-size: 14px;
  font-weight: 300;
  text-transform: uppercase;
  line-height: normal;
}

.details-custom-options {
  font-size: 14px;
  font-weight: 300;
}

.lp-product-info__amount-name {
  margin-bottom: 5px;
}

.lp-product-info__sizes .details-buy-in-time-block {
  display: none;
}
.lp-product-info__sizes .sizes-viewer-item {
  display: flex;
  align-items: center;
}

.details-payment .price-new,
.details-payment .price-old {
  font-size: 16px;
  line-height: 1.5;
}

.lp-product-info__price {
  font-size: 22px;
  font-weight: 300;
  line-height: 1;
}

.lp-product-info__bonus-string-sum {
  font-size: 12px;
  line-height: 1.5;
}

.lp-product-info__dimensions,
.lp-product-info__unit {
  display: flex;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.lp-product-info__dimensions-value,
.lp-product-info__dimensions-name,
.lp-product-info__unit-names,
.lp-product-info__unit-value {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

/* .details-buy-one-click > a {
    color: #000;
} */
.lp-product-info__weight-name,
.lp-product-info__weight-value,
.lp-product-info__briefproperties-name,
.lp-product-info__briefproperties-value {
  font-weight: 300;
}

.lp-product-info__briefproperties {
  font-size: 14px;
  text-transform: uppercase;
}

.modal-quickview .quickview-arrows-prev,
.modal-quickview .quickview-arrows-next {
  color: #fff;
}
.modal-quickview .quickview-arrows-prev:hover,
.modal-quickview .quickview-arrows-next:hover {
  color: #fff;
}

@media (min-width: 48em) {
  .lp-product-info__price {
    font-size: 24px;
    font-weight: 300;
  }
}
@media (min-width: 75em) {
  .lp-product-info__price {
    font-size: 28px;
    font-weight: 300;
  }
}
/**********************************   */
/********** products-view-label ************/
.gallery-block,
.lp-products-view-item-photo {
  position: relative;
  box-sizing: border-box;
}

.gallery-picture-labels,
.products-view__labels {
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;
  font-size: 0.75rem;
  line-height: 1.5;
}

.products-view-tile .products-view-label {
  text-align: right;
}

.products-view-label {
  margin-bottom: 0.3125rem;
  color: #fff;
  font-weight: bold;
  text-align: right;
}

.products-view-label-inner {
  border-radius: 0.3125rem;
  padding: 0.3125rem 0.625rem;
  display: inline-block;
  white-space: nowrap;
}

.products-view-label-sales,
.products-view-label-recommend {
  background-color: #58a9fd;
}

.products-view-label-discount,
.products-view-label-best {
  background-color: #cd0303;
}

.products-view-label-new {
  background-color: #b3be08;
}

/************************************   */
.products-view__colors .carousel-colors-wrap {
  margin-bottom: 12px;
  max-width: 100%;
}

.lp-block-products-view--one-big-picture .products-view__colors .carousel-colors-wrap .carousel-inner {
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 64em) {
  .lp-block-products-view--one-big-picture .products-view__colors .carousel-colors-wrap .carousel-inner {
    margin-left: 0;
    margin-right: 0;
  }
}
.lp-products-view__title--margin-bottom,
.lp-products-view__subtitle {
  margin-bottom: 24px;
}

.lp-products-view-item-price .price-discount {
  display: none;
}

.lp-products-view-item-price .price-old {
  -webkit-text-decoration: line-through;
  text-decoration: line-through;
  font-size: 16px;
}

.lp-block-products-view-alt .gallery-block {
  margin-bottom: 20px;
}

@media (min-width: 48em) {
  .lp-block-products-view-alt .gallery-block {
    margin-bottom: 0;
  }
}
.modal-quickview .lp-product-info__price .price-old,
.lp-block-products-view-alt .price-old {
  font-size: 24px;
  font-weight: normal;
  line-height: 1.83;
}

.modal-quickview .lp-product-info__price .price-new,
.lp-block-products-view-alt .price-new {
  font-size: 36px;
  font-weight: bold;
  line-height: 1.22;
}

/* .lp-products-view-item-price-inner {
    flex-direction: row-reverse;
} */
.lp-products-view-item-price-inner {
  align-items: baseline;
}

.price-old,
.price-new {
  padding: 0 5px;
}

.lp-products-view-item-photo-carousel .lp-products-view-item-photo {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.modal-quickview .carousel-nav-prev,
.modal-quickview .carousel-nav-next {
  color: #fff;
}

.modal-quickview .carousel-nav-prev,
.modal-quickview .carousel-nav-next,
.lp-products-view-item-photo-carousel .slick-arrow {
  width: 36px;
  height: 110px;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
  transition: opacity linear 0.5s;
  cursor: pointer;
}

.modal-quickview .carousel:hover .carousel-nav-next,
.modal-quickview .carousel:hover .carousel-nav-prev,
.lp-products-view-item-photo-carousel:hover .slick-arrow {
  opacity: 1;
  transition: opacity linear 0.5s;
}

.lp-products-view-item-photo-carousel .slick-next {
  right: 0;
}

.lp-products-view-item-photo-carousel .slick-prev {
  left: 0;
}

.lp-products-view-item-photo-carousel .slick-next::before,
.lp-products-view-item-photo-carousel .slick-prev::before {
  color: #fff;
}

.category-name-wrap {
  margin-right: -10px;
  margin-left: -10px;
  margin-bottom: 30px;
}

.category-name {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
}

.category-name-button {
  font-size: 14px;
  /* font-weight: 600; */
  line-height: 1.71;
}

.category-name:last-child {
  margin-bottom: 0;
}

.category-name.active {
  background-color: #1779fa;
  color: #fff;
}

.lp-products-view-item-brief-description {
  margin-bottom: 20px;
}

.lp-products-view-categories-item-photo-wrap {
  padding-bottom: 20px;
}

.lp-products-view-item-photo {
  margin: 0;
  text-align: center;
  margin-bottom: 0;
  padding: 0;
  display: block;
}

@media (max-width: 49em) {
  .lp-products-view-item-photo {
    height: auto !important;
  }
}
.lp-products-view-item-link-wrap {
  /* font-size: 20px; */
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 8px;
  min-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  word-break: break-word;
}

/* ie 11 */
.lp-products-view-item-link__text {
  flex-basis: 100%;
}

.lp-products-view-item-price {
  font-size: 20px;
  line-height: 1.3;
  margin-bottom: 17px;
}

.lp-products-view-item__button-wrap {
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.lp-products-view-by-categories-item-wrapper,
.lp-products-view-item-wrapper {
  margin-bottom: 30px;
  flex-grow: 1;
  flex-shrink: 0;
}

.category-name-select {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71;
}

.lp-block-products-view .lp-select-wrap {
  margin-bottom: 12px;
}

.lp-products-view-item {
  padding-left: 0;
  padding-right: 0;
  display: flex;
  height: 100%;
  flex-direction: column;
}

@media (min-width: 48em) {
  .lp-block-products-view .lp-products-view {
    margin-left: -20px;
    margin-right: -20px;
  }
  .lp-block-products-view .lp-products-view-item-wrapper {
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
  .lp-block-products-view .lp-products-view-item {
    padding-left: 0;
    padding-right: 0;
  }
  .lp-block-products-view .lp-products-view-item-photo-wrap {
    flex-grow: 1;
  }
  .lp-products-view-item-price {
    /* font-size: 24px; */
    font-size: 22px;
  }
  .lp-products-view-item-price .price-old {
    font-size: 20px;
  }
  /* .lp-products-view-item-wrapper {
      margin-left: -20px;
      margin-right: -20px;
  } */
  .lp-products-view-item {
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
  .lp-products-view__title--margin-bottom,
  .lp-products-view__subtitle {
    margin-bottom: 60px;
  }
  .lp-products-view-item-photo {
    /* padding: 20px; */
    padding-top: 0;
    margin-bottom: 4px;
  }
  .lp-products-view-item-link-wrap {
    /* font-size: 22px; */
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 12px;
    min-height: 48px;
  }
  .category-name-button {
    font-size: 14px;
    line-height: 1.71;
  }
}
@media (min-width: 75em) {
  .lp-products-view-item-link-wrap {
    /* font-size: 24px; */
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 12px;
    min-height: 48px;
  }
  .category-name-button {
    font-size: 16px;
    line-height: 1.5;
  }
}
/****************************   */
.lp-products-view-alt__price .price-discount {
  display: none;
}

.lp-products-view-alt__price .price-old {
  -webkit-text-decoration: line-through;
  text-decoration: line-through;
}

.lp-products-view-alt__name {
  font-size: 18px;
  line-height: 1.33;
  font-weight: 600;
  margin-bottom: 20px;
  word-break: break-word;
}

.lp-products-view-alt__price {
  font-size: 22px;
  line-height: 1.27;
  font-weight: 300;
  margin-bottom: 20px;
}

.details-description,
.details-briefdescription,
.lp-products-view-alt__description {
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 24px;
  line-height: normal;
}

.lp-products-view-item-photo-carousel {
  margin-bottom: 24px;
}

.lp-products-view-item-photo-carousel .slick-track {
  display: flex;
  align-items: center;
}

@media (min-width: 48em) {
  .lp-products-view-item-photo-carousel {
    margin-bottom: 0;
  }
  .lp-products-view-alt__name {
    font-size: 24px;
    line-height: 1.33;
    font-weight: 600;
    margin-bottom: 24px;
  }
  .lp-products-view-alt__price {
    font-size: 24px;
    line-height: 1.17;
    font-weight: 300;
    margin-bottom: 16px;
  }
  .details-description,
  .details-briefdescription,
  .lp-products-view-alt__description {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 42px;
    line-height: normal;
  }
}
@media (min-width: 75em) {
  .lp-products-view-alt__name {
    font-size: 28px;
    line-height: 1.14;
    font-weight: 600;
    margin-bottom: 28px;
  }
  .lp-products-view-alt__price {
    font-size: 28px;
    line-height: 1.43;
    font-weight: 300;
    margin-bottom: 28px;
  }
  .details-description,
  .details-briefdescription,
  .lp-products-view-alt__description {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 48px;
    line-height: normal;
  }
}
.product-view-landing-alt__row {
  margin-bottom: 20px;
}

.product-view-landing-alt:nth-child(odd) .product-view-landing-alt__row {
  flex-direction: row-reverse;
}

product-view-landing-alt-reverse .product-view-landing-alt:nth-child(odd) .product-view-landing-alt__row {
  flex-direction: row-reverse;
}

.product-view-landing-alt-reverse .product-view-landing-alt:nth-child(even) .product-view-landing-alt__row {
  flex-direction: row;
}

/******************* product-details **********************/
.product-details {
  /* &__discount {
      font-weight: bold;
      color: #fff;
      background-color: #FF3C45;
      font-size: 14px;
      line-height: 2.44;
  } */
}
.product-details .modal-video-trigger .lp-btn--link.svg-wrap {
  -webkit-text-decoration: none;
  text-decoration: none;
}
.product-details .modal-video-trigger .lp-btn--link.svg-wrap:hover {
  -webkit-text-decoration: underline;
  text-decoration: underline;
}
.product-details .slick-track {
  align-items: center;
}
.product-details .slick-center {
  text-align: center;
}
.product-details .iframe-responsive__custom-play-icon.modal-video-icon {
  width: 27px;
  height: 27px;
  margin-right: 14px;
}
.product-details .modal-video-icon {
  margin-right: 14px;
}
.product-details .iframe-responsive__custom-play-icon-wrap {
  margin-top: 15px;
  margin-bottom: 15px;
}
.product-details__name {
  font-weight: normal;
  font-size: 28px;
  margin-bottom: 12px;
}
.product-details__carousel-nav {
  display: none;
  margin: 20px 50px;
}
.product-details__carousel-for {
  margin-bottom: 20px;
  display: flex;
}
.product-details__carousel-for .details-carousel-item {
  flex-shrink: 0;
  max-width: 100%;
}
.product-details__addition-text {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  /* line-height: 2; */
}
.product-details__addition-block-wrap {
  border-radius: 8px;
  border: solid 1px #f1f1f1;
  margin-bottom: 32px;
}
.product-details__addition-block {
  flex-basis: 50%;
  max-width: 50%;
  padding-top: 16px;
  padding-bottom: 12px;
}
.product-details__addition-block--border-right {
  border-right: solid 1px #f1f1f1;
}
.product-details__text {
  font-size: 18px;
  font-weight: normal;
  line-height: 1.5;
  margin-bottom: 21px;
}
.product-details__icon {
  margin-bottom: 5px;
}
.product-details__icon .svg-icon {
  font-size: inherit;
}
.product-details__old-price {
  font-size: 18px;
  font-weight: bold;
  line-height: 2;
  -webkit-text-decoration: line-through;
  text-decoration: line-through;
  /* padding-right: 24px; */
  flex-shrink: 0;
  /* color: #A0A0A0; */
}
.product-details__discount, .product-details__discount-label {
  font-weight: bold;
  font-size: 14px;
  line-height: 1.9;
  /* padding-left: 8px;
  padding-right: 8px; */
  display: inline-block;
}
.product-details__discount *, .product-details__discount-label * {
  display: inline-block;
}
.product-details__price {
  font-size: 36px;
  font-weight: bold;
  line-height: 1.22;
  margin-bottom: 22px;
}
.product-details__image-wrap {
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  cursor: pointer;
  height: 104px;
}
.product-details__image-wrap--center {
  text-align: center;
}

.product-details__image-limit-height img {
  max-height: 104px;
}

.modal-quickview .product-details__carousel-nav {
  display: none;
}

.product-details__carousel--preview-admin {
  display: flex !important;
  justify-content: center;
}

.landing-product-view-modal .product-details__carousel-for .slick-arrow {
  display: none;
}

@media (min-width: 48em) {
  .product-details__content {
    margin-left: 16px;
  }
  .product-details__carousel-for {
    margin-bottom: 0;
  }
  .product-details__carousel-nav {
    display: block;
  }
  .modal-quickview .product-details__carousel-nav {
    display: flex;
  }
  /* .landing-product-view-modal .product-details__carousel-for .slick-arrow {
      display: none;
  } */
}
@media (min-width: 64em) {
  .product-details__name {
    font-size: 36px;
    margin-bottom: 16px;
  }
}
@media (min-width: 75em) {
  .product-details__name {
    font-size: 36px;
    margin-bottom: 24px;
  }
}
/********************************************************   */
/********* product-details-brief **********/
.lp-block-product-details-brief__name {
  padding-top: 24px;
  font-weight: normal;
  font-size: 18px;
  margin-bottom: 12px;
  line-height: 1.33;
}
.lp-block-product-details-brief__old-price {
  font-size: 18px;
  font-weight: bold;
  line-height: 2;
  -webkit-text-decoration: line-through;
  text-decoration: line-through;
  /* padding-right: 24px; */
  flex-shrink: 0;
  /* color: #A0A0A0; */
}
.lp-block-product-details-brief__discount, .lp-block-product-details-brief__discount-label {
  font-weight: bold;
  font-size: 14px;
  line-height: 1.9;
  /* padding-left: 8px;
  padding-right: 8px; */
  display: inline-block;
}
.lp-block-product-details-brief__discount *, .lp-block-product-details-brief__discount-label * {
  display: inline-block;
}
.lp-block-product-details-brief__price {
  font-size: 36px;
  font-weight: bold;
  line-height: 1.22;
  margin-bottom: 22px;
}
.lp-block-product-details-brief__image-wrap {
  padding-right: 0;
}

@media (min-width: 64em) {
  .lp-block-product-details-brief__name {
    font-size: 20px;
    margin-bottom: 16px;
  }
  .lp-block-product-details-brief__image-wrap {
    padding-right: 68px;
  }
}
@media (min-width: 75em) {
  .lp-block-product-details-brief__name {
    font-size: 24px;
    margin-bottom: 24px;
  }
}
/****************************************   */
.product-details-with-video__video-wrap {
  margin-bottom: 20px;
}

@media (min-width: 48em) {
  .product-details-with-video__video-wrap {
    margin-bottom: 0;
  }
}
/*********** lp-products-view-tile ***********/
.lp-products-view-tile .products-view-colors-empty {
  display: none;
}

.lp-block-products-view-tile__item {
  height: calc(100% - 18px);
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
  box-sizing: border-box;
  margin-bottom: 18px;
}

.lp-products-view-tile__item-link-wrap {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 20px;
  word-break: break-word;
}

.lp-products-view-tile__info {
  flex-grow: 1;
  padding: 32px 32px 0;
}

.lp-block-products-view-tile__item-brief-description {
  font-size: 16px;
  line-height: 1.5;
}

.lp-block-products-view-tile__item-price {
  font-weight: bold;
  margin-top: 20px;
  font-size: 22px;
  margin-bottom: 36px;
}

.lp-block-products-view-tile__item-price-block .price-discount {
  display: none;
}

.lp-block-products-view-tile__item-price-block .price-old {
  -webkit-text-decoration: line-through;
  text-decoration: line-through;
  font-size: 18px;
}

@media (min-width: 48em) {
  .lp-products-view-tile .products-view-colors-empty {
    display: block;
  }
  .lp-products-view-tile__item-link-wrap {
    font-size: 34px;
  }
  .lp-block-products-view-tile__item-price {
    font-size: 24px;
  }
}
/**********************************   */
.lp-block-products-view__empty--admin {
  text-align: center;
  padding: 20px;
}

.lp-block-products-view-tile__item-photo {
  margin: 32px 0 0;
  position: relative;
}

.lp-product-info__item:last-child {
  margin-bottom: 15px;
}

/******* products-view-background *********/
.lp-products-view-background {
  margin-left: -20px;
  margin-right: -20px;
}
.lp-products-view-background .lp-products-view-item-background-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.lp-products-view-background .lp-products-view-item-background-inner {
  position: relative;
  /* min-height: 225px; */
  padding: 25px;
  display: flex;
  /* justify-content: center; */
}
.lp-products-view-background .lp-products-view-background-wrapper {
  flex-grow: 0;
  flex-shrink: 0;
}
.lp-products-view-background .lp-products-view-background {
  margin-left: -20px;
  margin-right: -20px;
}
.lp-products-view-background .lp-products-view-item-background {
  padding-left: 20px;
  padding-right: 20px;
}
.lp-products-view-background .lp-products-view-item-price {
  font-size: 16px;
}
.lp-products-view-background .products-view__labels {
  position: static;
  margin-left: -3px;
  margin-right: -3px;
}
.lp-products-view-background .products-view-label {
  padding-right: 3px;
  padding-left: 3px;
  display: inline-block;
}

.lp-products-view-item-background__col {
  width: 100%;
}

.lp-products-view-item__images-wrap {
  position: relative;
}

/* lp-block-products-view-one-with-big-picture */
.lp-block-products-view--one-big-picture .lp-products-view-item-photo {
  display: block;
}
.lp-block-products-view--one-big-picture .lp-products-view-item-photo img:not(.js-qazy-loading) {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.lp-block-products-view--one-big-picture .lp-products-view-item-link-wrap {
  min-height: 0;
}
.lp-block-products-view--one-big-picture .lp-products-view-item {
  text-align: left;
}

.gallery-photos-item {
  padding: 0 !important;
}