@charset "UTF-8";
/* >480 - смартфоны */
/* >768 - планшеты */
/* >1024 - нетбуки, ноутбуки */
/* >1200 - десктоп */
/* >1600 - десктоп */
.hidden-xs {
  display: none !important;
}

.visible-xs {
  display: block !important;
}

@media (min-width: 48em) {
  .hidden-sm {
    display: none !important;
  }
  .visible-sm {
    display: block !important;
  }
}
@media (min-width: 64em) {
  .hidden-md {
    display: none !important;
  }
  .visible-md {
    display: block !important;
  }
}
@media (min-width: 75em) {
  .hidden-lg {
    display: none !important;
  }
  .visible-lg {
    display: block !important;
  }
}
@media (max-width: 63em) {
  .hidden-device-mobile {
    display: none !important;
  }
}
@media (min-width: 64em) {
  .hidden-device-desktop {
    display: none !important;
  }
}