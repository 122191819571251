@charset "UTF-8";
/* >480 - смартфоны */
/* >768 - планшеты */
/* >1024 - нетбуки, ноутбуки */
/* >1200 - десктоп */
/* >1600 - десктоп */
.lp-block-products-by-categories .category-name-wrap {
  flex-wrap: nowrap;
  overflow-x: auto;
}
.lp-block-products-by-categories .category-name-button {
  white-space: nowrap;
}
.lp-block-products-by-categories .category-name-wrap--links {
  /* .lp-btn--link {
      font-weight: 100;
  } */
}
.lp-block-products-by-categories .category-name-wrap--links .lp-btn--link-active {
  font-weight: bold;
  -webkit-text-decoration: underline;
  text-decoration: underline;
}
.lp-block-products-by-categories .category-name-wrap--links .category-name {
  margin-bottom: 5px;
}
.lp-block-products-by-categories .category-name-wrap--links button {
  font-weight: inherit;
}
.lp-block-products-by-categories .category-name-wrap--links .category-name-button {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 16px;
}
.lp-block-products-by-categories .category-name-wrap--links .category-name:first-child .category-name-button {
  padding-left: 0;
}
.lp-block-products-by-categories .category-name-wrap--links .category-name:last-child .category-name-button {
  padding-right: 0;
}

@media (min-width: 48em) {
  .lp-block-products-by-categories .category-name-wrap {
    flex-wrap: wrap;
  }
  .lp-block-products-by-categories .category-name-button {
    font-size: 18px;
  }
  .lp-block-products-by-categories .category-name-wrap--links .category-name {
    margin-bottom: 20px;
  }
}