.lp-news__more {
  margin-top: 20px;
  text-align: center;
}

.lp-news-item-wrap {
  margin-left: -10px;
  margin-right: -10px;
}

.lp-news-item {
  padding: 10px;
  box-sizing: border-box;
}
.lp-news-item--hoverable {
  cursor: pointer;
}
.lp-news-item__picture, .lp-news-item__datetime {
  margin-bottom: 10px;
}
.lp-news-item__title {
  margin-bottom: 15px;
}
.lp-news-item__picture {
  cursor: pointer;
}

.lp-news-modal__content {
  line-height: 1.5;
}