@font-face {
    font-family: advantshopfonts; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
    src: url('../../../../fonts/advantshopfonts/advantshopfonts.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Alegreya Sans SC';
    src: url('../../../../fonts/AlegreyaSansSC/AlegreyaSansSC-ExtraBoldItalic.woff2') format('woff2');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Alegreya Sans SC';
    src: url('../../../../fonts/AlegreyaSansSC/AlegreyaSansSC-BoldItalic.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Alegreya Sans SC';
    src: url('../../../../fonts/AlegreyaSansSC/AlegreyaSansSC-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Alegreya Sans SC';
    src: url('../../../../fonts/AlegreyaSansSC/AlegreyaSansSC-LightItalic.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Alegreya Sans SC';
    src: url('../../../../fonts/AlegreyaSansSC/AlegreyaSansSC-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Alegreya Sans SC';
    src: url('../../../../fonts/AlegreyaSansSC/AlegreyaSansSC-ThinItalic.woff2') format('woff2');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Alegreya Sans SC';
    src: url('../../../../fonts/AlegreyaSansSC/AlegreyaSansSC-Italic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Alegreya Sans SC';
    src: url('../../../../fonts/AlegreyaSansSC/AlegreyaSansSC-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Alegreya Sans SC';
    src: url('../../../../fonts/AlegreyaSansSC/AlegreyaSansSC-ExtraBold.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Cormorant;
    src: url('../../../../fonts/Cormorant/Cormorant-BoldItalic.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: Cormorant;
    src: url('../../../../fonts/Cormorant/Cormorant-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Cormorant;
    src: url('../../../../fonts/Cormorant/Cormorant-LightItalic.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: Cormorant;
    src: url('../../../../fonts/Cormorant/Cormorant-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Cormorant;
    src: url('../../../../fonts/Cormorant/Cormorant-SemiBoldItalic.woff2') format('woff2');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: Cormorant;
    src: url('../../../../fonts/Cormorant/Cormorant-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Cormorant;
    src: url('../../../../fonts/Cormorant/Cormorant-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Cormorant;
    src: url('../../../../fonts/Cormorant/Cormorant-Italic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: Merriweather;
    src: url('../../../../fonts/Merriweather/Merriweather-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Merriweather;
    src: url('../../../../fonts/Merriweather/Merriweather-Italic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: Merriweather;
    src: url('../../../../fonts/Merriweather/Merriweather-BoldItalic.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: Merriweather;
    src: url('../../../../fonts/Merriweather/Merriweather-LightItalic.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: Merriweather;
    src: url('../../../../fonts/Merriweather/Merriweather-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Merriweather;
    src: url('../../../../fonts/Merriweather/Merriweather-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Montserrat;
    src: url('../../../../fonts/Montserrat/Montserrat-SemiBoldItalic.woff2') format('woff2');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: Montserrat;
    src: url('../../../../fonts/Montserrat/Montserrat-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Montserrat;
    src: url('../../../../fonts/Montserrat/Montserrat-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Montserrat;
    src: url('../../../../fonts/Montserrat/Montserrat-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Montserrat;
    src: url('../../../../fonts/Montserrat/Montserrat-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Montserrat;
    src: url('../../../../fonts/Montserrat/Montserrat-ExtraBoldItalic.woff2') format('woff2');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: Montserrat;
    src: url('../../../../fonts/Montserrat/Montserrat-ExtraBold.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Montserrat;
    src: url('../../../../fonts/Montserrat/Montserrat-Italic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: Montserrat;
    src: url('../../../../fonts/Montserrat/Montserrat-BoldItalic.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: Montserrat;
    src: url('../../../../fonts/Montserrat/Montserrat-LightItalic.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: OpenSans;
    src: url('../../../../fonts/OpenSans/OpenSans-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: OpenSans;
    src: url('../../../../fonts/OpenSans/OpenSans-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: OpenSans;
    src: url('../../../../fonts/OpenSans/OpenSans-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: OpenSans;
    src: url('../../../../fonts/OpenSans/OpenSans-BoldItalic.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: OpenSans;
    src: url('../../../../fonts/OpenSans/OpenSans-ExtraBoldItalic.woff2') format('woff2');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: OpenSans;
    src: url('../../../../fonts/OpenSans/OpenSans-SemiBoldItalic.woff2') format('woff2');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: OpenSans;
    src: url('../../../../fonts/OpenSans/OpenSans-ExtraBold.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: OpenSans;
    src: url('../../../../fonts/OpenSans/OpenSans-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: OpenSans;
    src: url('../../../../fonts/OpenSans/OpenSans-LightItalic.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: OpenSans;
    src: url('../../../../fonts/OpenSans/OpenSans-Italic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Playfair Display';
    src: url('../../../../fonts/PlayfairDisplay/PlayfairDisplay-Italic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Playfair Display';
    src: url('../../../../fonts/PlayfairDisplay/PlayfairDisplay-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Playfair Display';
    src: url('../../../../fonts/PlayfairDisplay/PlayfairDisplay-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Playfair Display';
    src: url('../../../../fonts/PlayfairDisplay/PlayfairDisplay-BoldItalic.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: Roboto;
    src: url('../../../../fonts/Roboto/Roboto-BoldItalic.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: Roboto;
    src: url('../../../../fonts/Roboto/Roboto-Italic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: Roboto;
    src: url('../../../../fonts/Roboto/Roboto-LightItalic.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: Roboto;
    src: url('../../../../fonts/Roboto/Roboto-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Roboto;
    src: url('../../../../fonts/Roboto/Roboto-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Roboto;
    src: url('../../../../fonts/Roboto/Roboto-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Roboto;
    src: url('../../../../fonts/Roboto/Roboto-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Spectral SC';
    src: url('../../../../fonts/SpectralSC/SpectralSC-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Spectral SC';
    src: url('../../../../fonts/SpectralSC/SpectralSC-ExtraBold.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Spectral SC';
    src: url('../../../../fonts/SpectralSC/SpectralSC-Italic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Spectral SC';
    src: url('../../../../fonts/SpectralSC/SpectralSC-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Spectral SC';
    src: url('../../../../fonts/SpectralSC/SpectralSC-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Spectral SC';
    src: url('../../../../fonts/SpectralSC/SpectralSC-BoldItalic.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Spectral SC';
    src: url('../../../../fonts/SpectralSC/SpectralSC-LightItalic.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Spectral SC';
    src: url('../../../../fonts/SpectralSC/SpectralSC-SemiBoldItalic.woff2') format('woff2');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Spectral SC';
    src: url('../../../../fonts/SpectralSC/SpectralSC-ExtraBoldItalic.woff2') format('woff2');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Spectral SC';
    src: url('../../../../fonts/SpectralSC/SpectralSC-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Ubuntu;
    src: url('../../../../fonts/Ubuntu/Ubuntu-Italic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: Ubuntu;
    src: url('../../../../fonts/Ubuntu/Ubuntu-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Ubuntu;
    src: url('../../../../fonts/Ubuntu/Ubuntu-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Ubuntu;
    src: url('../../../../fonts/Ubuntu/Ubuntu-LightItalic.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: Ubuntu;
    src: url('../../../../fonts/Ubuntu/Ubuntu-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Ubuntu;
    src: url('../../../../fonts/Ubuntu/Ubuntu-BoldItalic.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: advlp;
    src: url('../../../../fonts/advlp/advlp.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/* logogenerator */
@font-face {
    font-family: AstakhovDished;
    src: url('../../../../fonts/AstakhovDished/AstakhovDished.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
}

@font-face {
    font-family: Dobrozrachniy;
    src: url('../../../../fonts/Dobrozrachniy/Dobrozrachniy-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fakedes Outline';
    src: url('../../../../fonts/Fakedes_Outline/FakedesOutline.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Jazz Ball';
    src: url('../../../../fonts/JazzBall/JazzBall.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
}

@font-face {
    font-family: Krabuler;
    src: url('../../../../fonts/Krabuler/RFKrabuler-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Kurica Lapoi 1.3';
    src: url('../../../../fonts/KuricaLapoi1.3/KuricaLapoi1.3.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
}

@font-face {
    font-family: Pelmeshka;
    src: url('../../../../fonts/Pelmeshka/Pelmeshka.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */

/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */

/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'advlp';
    src:url('/fonts/../font/advlp.svg?36185738#advlp') format('svg');
  }
}
*/

[class^='icon-lp-']::before,
[class*=' icon-lp-']::before {
    font-family: advlp; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;

    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes */
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */

    /* remove if not needed */
    margin-left: 0.2em;

    /* you can be more comfortable with increased icons size */

    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */

    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-lp-email::before {
    content: '\e800';
}

/* '' */
.icon-lp-phone::before {
    content: '\e801';
}

/* '' */
.icon-lp-right-open::before {
    content: '\e802';
}

/* '' */
.icon-lp-plus-circled::before {
    content: '\e803';
}

/* '' */
.icon-lp-trash-empty::before {
    content: '\e804';
}

/* '' */
.icon-lp-down::before {
    content: '\e805';
}

/* '' */
.icon-lp-up::before {
    content: '\e806';
}

/* '' */
.icon-lp-plus::before {
    content: '\e807';
}

/* '' */
.icon-lp-cog::before {
    content: '\e808';
}

/* '' */
.icon-lp-cancel::before {
    content: '\e809';
}

/* '' */
.icon-lp-ok::before {
    content: '\e80a';
}

/* '' */
.icon-lp-arrows-ccw::before {
    content: '\e80b';
}

/* '' */
.icon-lp-trash::before {
    content: '\e80c';
}

/* '' */
.icon-lp-left::before {
    content: '\e80d';
}

/* '' */
.icon-lp-pencil::before {
    content: '\e80e';
}

/* '' */
.icon-lp-eye::before {
    content: '\e80f';
}

/* '' */
.icon-lp-eye-off::before {
    content: '\e810';
}

/* '' */
.icon-lp-up-open::before {
    content: '\e811';
}

/* '' */
.icon-lp-down-open::before {
    content: '\e812';
}

/* '' */
.icon-lp-left-open::before {
    content: '\e813';
}

/* '' */
.icon-lp-check::before {
    content: '\e814';
}

/* '' */
.icon-lp-cog-1::before {
    content: '\e815';
}

/* '' */
.icon-lp-telegram::before {
    content: '\e816';
}

/* '' */
.icon-lp-left-open-mini::before {
    content: '\e817';
}

/* '' */
.icon-lp-right-open-mini::before {
    content: '\e818';
}

/* '' */
.icon-lp-down-dir::before {
    content: '\e819';
}

/* '' */
.icon-lp-up-dir::before {
    content: '\e81a';
}

/* '' */
.icon-lp-cancel-1::before {
    content: '\e81b';
}

/* '' */
.icon-lp-viber::before {
    content: '\e81c';
}

/* '' */
.icon-lp-right-open-1::before {
    content: '\f006';
}

/* '' */
.icon-lp-left-open-1::before {
    content: '\f007';
}

/* '' */
.icon-lp-twitter::before {
    content: '\f099';
}

/* '' */
.icon-lp-facebook::before {
    content: '\f09a';
}

/* '' */
.icon-lp-docs::before {
    content: '\f0c5';
}

/* '' */
.icon-lp-menu::before {
    content: '\f0c9';
}

/* '' */
.icon-lp-exchange::before {
    content: '\f0ec';
}

/* '' */
.icon-lp-spinner::before {
    content: '\f110';
}

/* '' */
.icon-lp-youtube::before {
    content: '\f167';
}

/* '' */
.icon-lp-instagram::before {
    content: '\f16d';
}

/* '' */
.icon-lp-vkontakte::before {
    content: '\f189';
}

/* '' */
.icon-lp-whatsapp::before {
    content: '\f232';
}

/* '' */
.icon-lp-odnoklassniki::before {
    content: '\f263';
}

/* '' */

@font-face {
    font-family: 'Roboto Condensed';
    src: url('../../../../fonts/RobotoCondensed/RobotoCondensed-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('../../../../fonts/RobotoCondensed/RobotoCondensed-LightItalic.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('../../../../fonts/RobotoCondensed/RobotoCondensed-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('../../../../fonts/RobotoCondensed/RobotoCondensed-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('../../../../fonts/RobotoCondensed/RobotoCondensed-Italic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('../../../../fonts/RobotoCondensed/RobotoCondensed-BoldItalic.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto Slab';
    src: url('../../../../fonts/RobotoSlab/RobotoSlab-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Slab';
    src: url('../../../../fonts/RobotoSlab/RobotoSlab-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Slab';
    src: url('../../../../fonts/RobotoSlab/RobotoSlab-ExtraBold.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Slab';
    src: url('../../../../fonts/RobotoSlab/RobotoSlab-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Slab';
    src: url('../../../../fonts/RobotoSlab/RobotoSlab-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: Rubik;
    src: url('../../../../fonts/Rubik/Rubik-LightItalic.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: Rubik;
    src: url('../../../../fonts/Rubik/Rubik-BoldItalic.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: Rubik;
    src: url('../../../../fonts/Rubik/Rubik-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: Rubik;
    src: url('../../../../fonts/Rubik/Rubik-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: Rubik;
    src: url('../../../../fonts/Rubik/Rubik-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: Rubik;
    src: url('../../../../fonts/Rubik/Rubik-Italic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('../../../../fonts/IBMPlexSans/IBMPlexSans.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('../../../../fonts/IBMPlexSans/IBMPlexSans-LightItalic.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('../../../../fonts/IBMPlexSans/IBMPlexSans-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('../../../../fonts/IBMPlexSans/IBMPlexSans-SemiBoldItalic.woff2') format('woff2');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('../../../../fonts/IBMPlexSans/IBMPlexSans-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('../../../../fonts/IBMPlexSans/IBMPlexSans-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('../../../../fonts/IBMPlexSans/IBMPlexSans-BoldItalic.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('../../../../fonts/IBMPlexSans/IBMPlexSans-Italic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: Scada;
    src: url('../../../../fonts/Scada/Scada-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: Scada;
    src: url('../../../../fonts/Scada/Scada-Italic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: Scada;
    src: url('../../../../fonts/Scada/Scada-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: Scada;
    src: url('../../../../fonts/Scada/Scada-BoldItalic.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: Inter;
    src: url('../../../../fonts/Inter/Inter-Black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: Inter;
    src: url('../../../../fonts/Inter/Inter-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: Inter;
    src: url('../../../../fonts/Inter/Inter-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: Inter;
    src: url('../../../../fonts/Inter/Inter-ExtraBold.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: Inter;
    src: url('../../../../fonts/Inter/Inter-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: Inter;
    src: url('../../../../fonts/Inter/Inter-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'PT Sans';
    src: url('../../../../fonts/PTSans/PTSans-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PT Sans';
    src: url('../../../../fonts/PTSans/PTSans-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'PT Sans';
    src: url('../../../../fonts/PTSans/PTSans-Italic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'PT Sans';
    src: url('../../../../fonts/PTSans/PTSans-BoldItalic.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'PT Serif';
    src: url('../../../../fonts/PTSerif/PTSerif-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PT Serif';
    src: url('../../../../fonts/PTSerif/PTSerif-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'PT Serif';
    src: url('../../../../fonts/PTSerif/PTSerif-BoldItalic.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'PT Serif';
    src: url('../../../../fonts/PTSerif/PTSerif-Italic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: Oswald;
    src: url('../../../../fonts/Oswald/Oswald-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: Oswald;
    src: url('../../../../fonts/Oswald/Oswald-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: Oswald;
    src: url('../../../../fonts/Oswald/Oswald-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: Oswald;
    src: url('../../../../fonts/Oswald/Oswald-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
