@charset "UTF-8";
/* >480 - смартфоны */
/* >768 - планшеты */
/* >1024 - нетбуки, ноутбуки */
/* >1200 - десктоп */
/* >1600 - десктоп */
.lp-cart-trigger {
  border-radius: 50%;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  color: #c0c0c0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #fff;
  border: 0;
  cursor: pointer;
  padding: 10px;
}
.lp-cart-trigger:hover {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.8);
  color: #808080;
}
.lp-cart-trigger--fixed {
  position: fixed;
  z-index: 250;
  top: 100px;
  right: 16px;
}
.lp-cart-trigger__count {
  position: absolute;
  border-radius: 50%;
  padding: 2px;
  color: #fff;
  background-color: rgb(242, 62, 53);
  text-align: center;
  min-width: 20px;
  min-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: -6px;
  bottom: -6px;
}

.lp-cart__header {
  margin-bottom: 22px;
  text-transform: uppercase;
}
.lp-cart__item {
  padding-left: 8px;
  padding-right: 8px;
}
.lp-cart__item:last-child .lp-cart__col {
  border-bottom: 1px solid rgb(216, 216, 216);
}
.lp-cart__item:last-child .lp-cart__col--collapse {
  border-bottom: 0;
}
.lp-cart__item-title {
  margin-bottom: 8px;
}
.lp-cart__col {
  padding-top: 24px;
  padding-bottom: 24px;
  border-top: 1px solid rgb(216, 216, 216);
}
.lp-cart__col:first-child {
  padding-left: 0;
}
.lp-cart__col:last-child {
  padding-right: 0;
}
.lp-cart__col--delete {
  min-width: 36px;
}
.lp-cart__title {
  font-size: 16px;
  font-weight: 600;
}
.lp-cart-properties {
  font-size: 16px;
  list-style: none;
  padding: 0;
  margin: 8px 0 0;
}
.lp-cart__delete--icon {
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  color: #8f969c;
}
.lp-cart__delete--icon:hover {
  color: #000;
}
.lp-cart__spinbox .spinbox-input {
  border-radius: 4px;
  border: solid 1px #d8d8d8;
  padding: 12px 18px;
  line-height: normal;
  box-sizing: border-box;
  width: 100%;
  font-size: 18px;
}
.lp-cart__spinbox .spinbox-more,
.lp-cart__spinbox .spinbox-less {
  color: #8f969c;
  cursor: pointer;
}
.lp-cart__spinbox .spinbox-more:hover,
.lp-cart__spinbox .spinbox-less:hover {
  color: #000;
}
.lp-cart__empty {
  text-align: center;
}
.lp-cart__loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.lp-cart__error {
  color: #e20a0a;
  padding-top: 24px;
}

.lp-cart-result,
.lp-cart-btn-confirm {
  padding-top: 24px;
  font-weight: 600;
}

.lp-cart-bill {
  font-size: 16px;
  text-align: center;
}
.lp-cart-bill__price--old {
  -webkit-text-decoration: line-through;
  text-decoration: line-through;
}
.lp-cart-bill__price--new, .lp-cart-bill__price--default {
  font-weight: 600;
}
.lp-cart-bill__discount {
  margin-top: 12px;
  color: #ce1a1a;
}

.lp-cart-cards-form__title {
  margin-bottom: 8px;
}
.lp-cart-cards-form__widget .input-small {
  border-radius: 4px;
  border: solid 1px #d8d8d8;
  padding: 12px 18px;
  line-height: normal;
  box-sizing: border-box;
  width: 100%;
  font-size: 18px;
}

.adv-modal .lp-cart-modal {
  max-width: 740px;
  width: 100%;
}
.adv-modal .lp-cart-modal__header, .adv-modal .lp-cart-modal__content, .adv-modal .lp-cart-modal__footer {
  border: 0;
}
.adv-modal .lp-cart-modal__header {
  padding: 24px;
}
.adv-modal .lp-cart-modal__content {
  padding: 0 24px;
}
.adv-modal .lp-cart-modal__footer {
  padding: 0 24px 24px;
}

.lp-cart-spinbox__unit,
.lp-cart__unit-block {
  text-align: center;
  font-size: 18px;
}

.lp-cart-spinbox__unit {
  margin-top: 8px;
}
.lp-cart-spinbox__error {
  text-align: center;
  color: #f23e35;
}

.menu-state--opened .lp-cart-trigger {
  display: none;
}

@media (min-width: 48em) {
  .lp-cart-result,
  .lp-cart-btn-confirm,
  .lp-cart__error {
    padding-top: 32px;
  }
  .lp-cart-bill__price--old, .lp-cart-bill__price--new, .lp-cart-bill__price--default {
    display: block;
  }
  .lp-cart-bill__discount-value {
    display: block;
  }
  .adv-modal .lp-cart-modal__header {
    padding: 32px;
  }
  .adv-modal .lp-cart-modal__content {
    padding: 0 32px;
  }
  .adv-modal .lp-cart-modal__footer {
    padding: 0 32px 32px;
  }
  .lp-cart-trigger {
    padding: 20px;
  }
  .lp-cart-trigger--fixed {
    top: 84px;
    right: 64px;
  }
  .lp-cart-trigger__count {
    padding: 4px;
    right: 0;
    bottom: 0;
  }
}