@charset "UTF-8";
/* >480 - смартфоны */
/* >768 - планшеты */
/* >1024 - нетбуки, ноутбуки */
/* >1200 - десктоп */
/* >1600 - десктоп */
.lp-accordion {
  list-style: none;
  margin: 0 0 16px;
  padding: 0;
}
.lp-accordion__item {
  padding: 0;
  margin: 0;
  border-radius: 4px;
}
.lp-accordion__trigger {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.lp-accordion__trigger::after {
  content: "\f006";
  font-family: advlp, sans-serif;
  transform: rotate(90deg);
}
.lp-accordion__state {
  opacity: 0;
  position: absolute;
}
.lp-accordion__state:checked + .lp-accordion__item {
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.07);
}
.lp-accordion__state:checked + .lp-accordion__item--box-shadow-none {
  box-shadow: none;
}
.lp-accordion__state:checked + .lp-accordion__item .lp-accordion__trigger::after {
  transform: rotate(270deg);
}
.lp-accordion__state:checked + .lp-accordion__item .lp-accordion__content {
  display: block;
}
.lp-accordion__content {
  display: none;
}
.lp-accordion__content-divider {
  height: 1px;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
}

.lp-accordion-theme--list .lp-accordion__trigger {
  padding: 24px 41px 24px 20px;
}
.lp-accordion-theme--list .lp-accordion__trigger::after {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%) rotate(90deg);
}
.lp-accordion-theme--list .lp-accordion__state:checked + .lp-accordion__item .lp-accordion__trigger::after {
  transform: translateY(-50%) rotate(270deg);
}
.lp-accordion-theme--list .lp-accordion__content {
  padding-left: 20px;
}
.lp-accordion-theme--list .lp-accordion__item {
  margin-bottom: 16px;
}
.lp-accordion-theme--list .lp-accordion__content-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.lp-accordion-theme--list .lp-accordion__content-item {
  margin: 0;
  padding: 24px 20px 24px 0;
}

@media (min-width: 48em) {
  .lp-accordion-theme--list .lp-accordion__trigger {
    padding: 24px 60px 24px 32px;
  }
  .lp-accordion-theme--list .lp-accordion__trigger::after {
    right: 32px;
  }
  .lp-accordion-theme--list .lp-accordion__content {
    padding-left: 32px;
  }
}