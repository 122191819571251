@charset "UTF-8";
/* >480 - смартфоны */
/* >768 - планшеты */
/* >1024 - нетбуки, ноутбуки */
/* >1200 - десктоп */
/* >1600 - десктоп */
.lp-block-contacts-map__header {
  margin-bottom: 8px;
}

.lp-block-contacts-map__text {
  line-height: 1.5;
}

.lp-block-contacts-map__social {
  margin-top: 28px;
}
.lp-block-contacts-map__social .header__social:first-child {
  margin-left: 0;
}

.lp-block-contacts-map__contacts {
  padding-top: 30px;
  padding-bottom: 30px;
}

.lp-block-contacts-center {
  background-size: cover;
  background-position: center center;
}

.lp-block-contacts-center__social {
  margin-top: 48px;
}

.lp-block-contacts-map-background {
  position: relative;
}

.lp-block-contacts-map-background__wrap,
.lp-block-contacts-map-background__content-wrap {
  position: relative;
}

.lp-block-contacts-map-background__wrap {
  min-height: 560px;
  padding-right: 2rem;
  padding-left: 2rem;
  margin-right: auto;
  margin-left: auto;
}

.lp-block-contacts-map-background__iframe-wrap iframe {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 560px;
}

.lp-block-contacts-map-background__popup {
  padding-bottom: 30px;
  padding-top: 30px;
}

.lp-block-contacts-map__wrap iframe {
  border: 0;
  width: 100%;
  height: 560px;
}

.lp-block-contacts-buttons-socials .lp-block-contacts-buttons-social__btn {
  display: block;
  padding-top: 17px;
  padding-bottom: 18px;
  width: 100%;
  height: auto;
  /* border-radius: 3px; */
  align-items: center;
  color: #fff;
  overflow: hidden;
  font-size: 16px;
  margin: 5px;
  -webkit-text-decoration: none;
  text-decoration: none;
  cursor: pointer;
}
.lp-block-contacts-buttons-socials .lp-block-contacts-buttons-social__btn:hover {
  color: #fff;
  -webkit-text-decoration: none;
  text-decoration: none;
}
.lp-block-contacts-buttons-socials .lp-block-contacts-buttons-social__btn::before {
  font-size: 25px;
}
.lp-block-contacts-buttons-socials .lp-block-contacts-buttons-social__btn .social__name {
  display: inline-block;
  font-size: 16px;
  background-color: transparent;
  border: 0;
  border-radius: 0;
}
.lp-block-contacts-buttons-socials .lp-block-contacts-buttons-social__btn:last-child {
  margin-bottom: 0;
}

@media (min-width: 48em) {
  .lp-block-contacts-map-background__popup {
    position: absolute;
    top: 30px;
    right: 30px;
    padding: 30px;
    z-index: 5;
  }
  .lp-block-contacts-map-background__iframe-wrap {
    padding-right: 0;
    padding-left: 0;
  }
  .lp-block-contacts-map-background__iframe-wrap iframe {
    position: absolute;
    z-index: 0;
  }
}
.lp-block-contacts-buttons-socials-alt a.social {
  height: 55px;
  width: 55px;
  border-radius: 50%;
  font-size: 30px;
}