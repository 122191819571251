@charset "UTF-8";
/* >480 - смартфоны */
/* >768 - планшеты */
/* >1024 - нетбуки, ноутбуки */
/* >1200 - десктоп */
/* >1600 - десктоп */
.lp-form__title {
  line-height: 1.33;
}
.lp-form__title--vertical, .lp-form__title--horizontal {
  /* font-size: 24px; */
}
.lp-form__subtitle {
  font-size: 18px;
}
.lp-form__wrap {
  flex-wrap: nowrap;
}
.lp-form__body--vertical {
  margin-top: 20px;
}
.lp-form__subtitle--horizontal {
  margin-top: 48px;
}
.lp-form__subtitle--vertical, .lp-form__field, .lp-form__submit-block {
  margin-top: 8px;
  margin-bottom: 8px;
}
.lp-form__agreement {
  margin: 20px 0;
  font-size: 18px;
}
.lp-form__content--success {
  padding: 30px;
}
.lp-form__field-label {
  margin-bottom: 10px;
}

.lp-form-in-modal {
  width: 500px;
}

@media (min-width: 48em) {
  .lp-form--vertical {
    /* font-size: 36px; */
  }
  .lp-form--horizontal .lp-textarea {
    height: 50px;
  }
}
.lp-form__picture-block--horizontal {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.lp-form__picture-button-add {
  color: #dedede;
  background: #fff none;
  padding: 0;
  height: 100%;
  width: 100%;
  font-size: 50px;
  line-height: 1;
  cursor: pointer;
  min-height: 80px;
}

.lp-form__picture-item {
  padding-bottom: 8px;
}
.lp-form__picture-item--horizontal {
  min-width: 88px;
  max-width: 120px;
}
.lp-form__picture-item--horizontal .lp-form__picture-button-add {
  min-width: 110px;
}

.lp-form__picture {
  vertical-align: middle;
}

.lp-form__picture-item-wrap {
  position: relative;
  border: solid 1px #d8d8d8;
}

.lp-form__picture-delete {
  border: 0;
  padding: 0;
  background: transparent none;
  position: absolute;
  top: -6px;
  right: -6px;
  cursor: pointer;
}

.form-field-name,
.form-field-input {
  display: block;
}

.lp-form__filearchive {
  padding: 8px;
  text-align: center;
}
.lp-form__filearchive-icon {
  font-size: 2.25em;
  color: rgb(96, 96, 96);
}
.lp-form__filearchive-name {
  font-size: 13px;
  max-height: 36px;
  overflow: hidden;
  margin: 8px 0 0;
}