@charset "UTF-8";
/* >480 - смартфоны */
/* >768 - планшеты */
/* >1024 - нетбуки, ноутбуки */
/* >1200 - десктоп */
/* >1600 - десктоп */
.reviews-singleton__item {
  text-align: center;
}

.reviews-singleton__text {
  text-align: left;
  font-size: 18px;
  line-height: 1.6;
}

.slick-slide .reviews-singleton__picture {
  display: inline-block;
}

.reviews-singleton__picture-wrap {
  height: 100px;
  margin-bottom: 20px;
}

.reviews-singleton__author {
  font-size: 27px;
  margin-bottom: 10px;
}

.reviews-singleton__link {
  font-size: 25px;
  display: inline-block;
}

@media (min-width: 48em) {
  .reviews-singleton__text {
    font-size: 25px;
    line-height: 1.6;
    margin-bottom: 35px;
  }
}