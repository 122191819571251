.lp-btn,
.btn {
  -webkit-text-decoration: none;
  text-decoration: none;
  display: inline-flex;
  flex-wrap: wrap;
  vertical-align: middle;
  cursor: pointer;
  align-items: center;
  text-align: center;
  justify-content: center;
  box-sizing: border-box;
  border-style: solid;
  font-size: 16px;
  padding: 13px 36px;
  line-height: normal;
}
.lp-btn::after, .lp-btn::before,
.btn::after,
.btn::before {
  content: "";
  flex: 1 0 auto;
}
.lp-btn:hover, .lp-btn:active,
.btn:hover,
.btn:active {
  -webkit-text-decoration: none;
  text-decoration: none;
}
.lp-btn:focus,
.btn:focus {
  outline: none;
}

.lp-btn--sm {
  padding: 6px 12px;
  font-size: 12px;
}
.lp-btn--upper {
  text-transform: uppercase;
}
.lp-btn--expand {
  width: 100%;
}
.lp-btn--link {
  background-color: transparent;
  border: 0;
  color: currentcolor;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  -webkit-text-decoration: underline;
  text-decoration: underline;
}
.lp-btn--link:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
}
.lp-btn--disabled {
  opacity: 0.5;
  cursor: default;
}
.lp-btn--disabled:hover, .lp-btn--disabled:active {
  opacity: 0.5;
  cursor: default;
}
.lp-btn--invisible {
  padding: 0;
  margin: 0;
  border: 0;
  background: transparent none;
}

.btn-sm {
  padding: 6px 12px;
  font-size: 12px;
}

.lp-btn-group {
  display: inline-flex;
  align-items: center;
  margin-left: -10px;
  margin-right: -10px;
  flex-wrap: wrap;
}
.lp-btn-group__item--indent {
  padding: 10px;
}