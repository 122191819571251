@charset "UTF-8";
/* >480 - смартфоны */
/* >768 - планшеты */
/* >1024 - нетбуки, ноутбуки */
/* >1200 - десктоп */
/* >1600 - десктоп */
* {
  outline: none;
}

body {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

b,
strong {
  font-weight: bold;
}

p {
  padding: 0;
  margin: 16px 0 0;
}

iframe {
  border: 0;
}

figure {
  margin: 0;
}

h1,
.lp-h1,
.h1 {
  font-size: 32px;
  margin-bottom: 22px;
}

h2,
.lp-h2,
.h2 {
  font-size: 28px;
  margin-bottom: 12px;
}

h3,
.lp-h3,
.h3 {
  font-size: 16px;
  margin-bottom: 16px;
}

.lp-font-size--xsmall {
  font-size: 12px;
}

.lp-font-size--small {
  font-size: 14px;
}

.lp-font-size--middle {
  font-size: 16px;
}

.lp-font-size--large {
  font-size: 18px;
}

.slick-list {
  width: calc(100% - 1px); /* чтобы не выходил пиксель справа у слика */
  box-sizing: border-box;
}

@media (min-width: 64em) {
  h1,
  .lp-h1 {
    font-size: 48px;
    margin-bottom: 24px;
  }
  h2,
  .lp-h2 {
    font-size: 36px;
    margin-bottom: 16px;
  }
  .lp-h2--single {
    margin-bottom: 32px;
  }
  h3,
  .lp-h3 {
    font-size: 20px;
    margin-bottom: 32px;
  }
  .lp-font-size--xsmall {
    font-size: 14px;
  }
  .lp-font-size--small {
    font-size: 16px;
  }
  .lp-font-size--middle {
    font-size: 18px;
  }
  .lp-font-size--large {
    font-size: 24px;
  }
}
@media (min-width: 75em) {
  h1,
  .lp-h1 {
    font-size: 48px;
    margin-bottom: 24px;
  }
  h2,
  .lp-h2 {
    font-size: 36px;
    margin-bottom: 24px;
  }
  .lp-h2--single {
    margin-bottom: 48px;
  }
  h3,
  .lp-h3 {
    font-size: 24px;
    margin-bottom: 48px;
  }
}
.lp-h2--margin-none,
.lp-h1--margin-none,
.lp-h3--margin-none {
  margin-bottom: 0;
}

.slick-dots li:only-child {
  display: none;
}

.lp-product-description {
  padding: 0 0 20px;
}

.lp-old-price {
  -webkit-text-decoration: line-through;
  text-decoration: line-through;
}

.slick-slide img {
  display: inline-block;
}

.block-html-content {
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 20px;
  font-size: 20px;
}

.border-bottom-dashed {
  border-bottom: 1px dashed;
}

.video-view {
  position: relative;
}
.video-view__custom-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 1;
  border-radius: 50%;
  border: 11px solid #fff;
  background-color: #fff;
}
.video-view__custom-play-icon {
  width: 52px;
  height: 52px;
}

.background-attachment-scroll,
.background-attachment-fixed {
  background-size: cover;
  background-position: center center;
}

.background-attachment-scroll {
  background-attachment: scroll;
}

.background-attachment-fixed {
  background-attachment: fixed;
}

@media (max-device-width: 30em) {
  .background-attachment-fixed {
    background-attachment: scroll;
  }
}
/* кастомизация скролла */
.scroll-always-visible::-webkit-scrollbar {
  -webkit-appearance: none;
          appearance: none;
  width: 100px;
  height: 2px;
}

.scroll-always-visible::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

/***********************   */
/* Анимации при наведении */
.zoom-animation > img {
  overflow: hidden;
  transition: 1s;
}
.zoom-animation > img:hover {
  transform: scale(1.1);
}

.next-image-animation__item {
  height: 100%;
}

.next-image-animation {
  position: relative;
  /* &:hover img:nth-child(2n) {
      z-index: 0;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      opacity: 1;
  } */
}
.next-image-animation img {
  transition: opacity 0.2s linear;
}
.next-image-animation img:nth-child(2n) {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  /* z-index: -1; */
  opacity: 0;
}
.next-image-animation:hover img:not(:last-child) {
  opacity: 0;
}
.next-image-animation:hover img:not(:first-child) {
  /* z-index: 0; */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
}

/***********************   */
.mobile-version .modal {
  z-index: 3000 !important;
}
.mobile-version .modal .modal-content {
  margin: 0 10px;
}
.mobile-version .modal .modal-header {
  border-radius: 0.3125rem 0.3125rem 0 0;
  font-size: 16px;
  line-height: 1.25;
  font-weight: normal;
  padding: 16px 45px 12px 25px;
  border-bottom-color: #f0f0f0;
}
.mobile-version .modal .modal-header .modal-header-title {
  font-size: 16px;
  line-height: 1.25;
}
.mobile-version .modal .modal-body {
  width: auto;
  background-color: #f9f9f9;
}

.mobile-version .popover {
  z-index: 4000 !important;
}

.mobile-version .m-b {
  margin-bottom: 15px;
}

.mobile-version .blocks-constructor-modal .modal-content {
  display: block;
  font-size: 14px;
  font-family: Roboto, sans-serif;
  line-height: 1.6;
}
.mobile-version .blocks-constructor-modal .modal-content .blocks-constructor-row__name {
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
}
.mobile-version .blocks-constructor-modal .modal-content .blocks-constructor-label-text {
  line-height: 1.6;
  font-size: 14px;
  font-family: Roboto, sans-serif;
}
.mobile-version .blocks-constructor-modal .modal-content .tabs-horizontal .tabs-content {
  font-size: 14px;
  font-family: Roboto, sans-serif;
  line-height: 1.6;
  margin-top: 10px;
}

.mobile-version {
  /* stylelint-disable */
  /* stylelint-enable */
}
.mobile-version select.flatpickr-monthDropdown-months,
.mobile-version input.cur-year {
  box-shadow: none !important;
  padding: 0 0 0 0.5ch !important;
}
.mobile-version .flatpickr-innerContainer {
  margin-top: 10px;
}
.mobile-version .modal-content {
  display: block !important;
  height: 90vh;
}
.mobile-version .modal-content select,
.mobile-version .modal-content textarea {
  font-size: var(--input-font-size);
}
.mobile-version .modal-content .gallery-block {
  padding-right: 0;
}
.mobile-version .ui-grid-custom-pagination-select {
  background-color: #fff !important;
}
.mobile-version .blocks-constructor-row__name {
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
}
.mobile-version .blocks-constructor-label-text {
  line-height: 1.6;
  font-size: 14px;
  font-family: Roboto, sans-serif;
}
.mobile-version .tabs-horizontal .tabs-content {
  font-size: 14px;
  font-family: Roboto, sans-serif;
  line-height: 1.6;
}
.mobile-version .blocks-constructor-buttons-color-scheme {
  background-color: #f9f9f9;
}
.mobile-version .blocks-constructor-buttons-color-scheme .blocks-constructor-buttons-color-scheme__preview {
  min-height: 120px;
}
.mobile-version .btn-success:focus, .mobile-version .btn-success:active:focus,
.mobile-version .btn-primary:focus,
.mobile-version .btn-primary:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.mobile-version .btn-success:not(.btn-outline):not([disabled]),
.mobile-version .btn-primary:not(.btn-outline):not([disabled]) {
  color: #fff;
  background-color: #2196f3;
  border-color: #2196f3;
}
.mobile-version .btn-default {
  border: 1px solid #ddd;
  color: #0078bd;
  background: white;
}
.mobile-version select:not(.ng-dirty.ng-invalid):not(.admin-signin-field),
.mobile-version textarea:not(.ng-dirty.ng-invalid):not(.admin-signin-field),
.mobile-version .form-control:not([type=submit]):not([type=button]):not([type=checkbox]):not([type=radio]):not(.ng-dirty.ng-invalid):not(.ui-select-container),
.mobile-version input:not([type=submit]):not([type=button]):not([type=checkbox]):not([type=radio]):not(.ng-dirty.ng-invalid):not(.ui-select-search):not(.admin-signin-field):not(#CaptchaCode):not(.auth-field) {
  border: none;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.05);
}
.mobile-version .pagination > .active > span,
.mobile-version .pagination > .active > a {
  border-color: #f9f9f9;
  -webkit-text-decoration: none;
  text-decoration: none;
}
.mobile-version .pagination > li > a,
.mobile-version .pagination > li > span {
  border-color: #f9f9f9;
}
.mobile-version .blocks-constructor-select,
.mobile-version .logo-generator-form .select-custom {
  border: none !important;
  position: relative;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.05);
}
.mobile-version .adv-body-fixed-touch {
  position: relative !important;
}
.mobile-version .lp-grid__remove {
  flex-basis: auto !important;
}
.mobile-version .lp-admin-panel-wrap {
  z-index: 1500;
}
.mobile-version .lp-admin-panel-item:not(:first-child) {
  display: block;
}
.mobile-version .lp-settings-modal .tabs-headers.tabs-headers--common-settings {
  padding-left: 0 !important;
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  overflow: scroll hidden;
}
.mobile-version .lp-settings-modal .tabs-headers.tabs-headers--common-settings .tabs-header-item-link {
  padding: 15px;
}
.mobile-version .lp-settings-modal .tabs-headers.tabs-headers--common-settings .tabs-header-item {
  margin: 0;
}
.mobile-version .lp-settings-modal .tabs-headers.tabs-headers--common-settings .tabs-header-item.tabs-header-active {
  border-width: 0 0 0 4px;
  background-color: rgb(238, 238, 238);
}
.mobile-version .lp-settings-modal-overlay .modal-content {
  padding: 0 10px;
  display: block;
}
.mobile-version .lp-admin-panel-wrap + div.lp-main .lp-menu-header-container--open .lp-menu-header__mobile-padding-for-panel {
  padding-top: 42px;
}
.mobile-version .lp-admin-panel-wrap + div.lp-main .lp-menu-header-container--open .lp-menu-header__mobile-padding-for-panel .lp-menu-header__close {
  top: 69px;
}
.mobile-version .myaccount-page__tabs {
  display: block;
  overflow: hidden;
  /* stylelint-disable */
  /* stylelint-enable */
}
.mobile-version .myaccount-page__tabs .tabs-headers.tabs-headers--width {
  display: flex;
  overflow: scroll;
  width: 100%;
  padding: 0;
}
.mobile-version .myaccount-page__tabs .tabs-header-item {
  flex-shrink: 0;
}
.mobile-version .myaccount-page__tabs .tabs-header-item-link {
  border-radius: 0.3125rem;
  color: currentcolor;
  -webkit-text-decoration: none;
  text-decoration: none;
}
.mobile-version .myaccount-page__tabs .tab-content {
  margin-top: 24px;
}
.mobile-version .myaccount-page__tabs .address-list .address-list-item .row {
  flex-direction: column;
  align-items: flex-start;
}
.mobile-version .myaccount-page__tabs .address-list .address-list-item .row .address-list-address-text {
  display: inline-block;
  margin-bottom: 16px;
}
.mobile-version .myaccount-page__tabs .address-list .address-list-item .row .end-xs.col-xs-5 {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}
.mobile-version .myaccount-page__tabs .address-list .address-list-item .row .end-xs.col-xs-5 .address-controls-item {
  margin-left: 0;
}
.mobile-version .myaccount-page__tabs select:not(.ng-dirty.ng-invalid):not(.admin-signin-field),
.mobile-version .myaccount-page__tabs textarea:not(.ng-dirty.ng-invalid):not(.admin-signin-field),
.mobile-version .myaccount-page__tabs .form-control:not([type=submit]):not([type=button]):not([type=checkbox]):not([type=radio]):not(.ng-dirty.ng-invalid):not(.ui-select-container),
.mobile-version .myaccount-page__tabs input:not([type=submit]):not([type=button]):not([type=checkbox]):not([type=radio]):not(.ng-dirty.ng-invalid):not(.ui-select-search):not(.admin-signin-field):not(#CaptchaCode):not(.auth-field) {
  border-width: 1px;
  border-style: solid;
  border-color: var(--input-border-color);
  box-shadow: none;
  color: var(--input-color);
  padding: var(--input-indents);
  box-sizing: border-box;
  line-height: 1.5;
  border-radius: 3px;
  font-size: var(--input-font-size);
  height: var(--input-height);
}

.mobile-version.edit-mode .lp-admin-panel-wrap {
  margin-bottom: 30px;
}
.mobile-version.edit-mode .lp-admin-panel-wrap + div.lp-main .lp-menu-header-container--open .lp-menu-header__mobile-padding-for-panel {
  padding-top: 42px;
}
.mobile-version.edit-mode .lp-admin-panel-wrap + div.lp-main .lp-menu-header-container--open .lp-menu-header__mobile-padding-for-panel .lp-menu-header__close {
  top: 68px;
}
.mobile-version.edit-mode .overflow-hidden-for-modal-ios .lp-admin-panel-wrap + div.lp-main .lp-blocks-constructor-buttons {
  display: none;
}
.mobile-version.edit-mode .lp-block-exit-popup-buttons-countdown .lp-block-products-view__empty--admin .lp-btn {
  margin-bottom: 8px;
}