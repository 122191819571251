.link-service,
.link-service--secondary {
  cursor: pointer;
}

.link-service {
  color: #2d9ced;
  -webkit-text-decoration: underline;
  text-decoration: underline;
}
.link-service:hover {
  color: #8bc8f5;
  -webkit-text-decoration: none;
  text-decoration: none;
}
.link-service:active {
  color: #0e66a6;
}

.link-service--secondary {
  color: #4b4f58;
  -webkit-text-decoration: underline;
  text-decoration: underline;
}
.link-service--secondary:hover {
  color: #7b818e;
  -webkit-text-decoration: none;
  text-decoration: none;
}
.link-service--secondary:active {
  color: #1c1e21;
}

.link-decoration-invert,
.link-decoration-none,
.link-text-decoration-none,
.link-decoration-none:hover,
.link-text-decoration-none:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
}

.link-decoration-invert:hover {
  -webkit-text-decoration: underline;
  text-decoration: underline;
}

.link-dotted,
.link-dotted-invert {
  border-bottom: 1px transparent dotted;
}

.link-dotted {
  border-bottom-color: #fff;
}

.link-dotted:hover {
  border-bottom-color: transparent;
}