.lp-select-wrap {
  position: relative;
}

.lp-select-arrow-emul {
  position: absolute;
  right: 10px;
  top: 54%;
  transform: translateY(-50%);
  pointer-events: none;
}

.lp-input,
.lp-select,
.lp-textarea,
.input-small,
.textarea-small,
.select-custom select,
.lp-form__picture-button-add {
  border: solid 1px #d8d8d8;
}

.lp-input,
.lp-select,
.lp-textarea,
.input-small,
.textarea-small,
.select-custom select {
  border-radius: 4px;
  padding: 12px 18px;
  font-size: 18px;
  line-height: normal;
  box-sizing: border-box;
  width: 100%;
  vertical-align: middle;
}

.ng-submitted.ng-dirty .ng-invalid.lp-form__picture-button-add {
  border-color: #f15959;
}

.lp-select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  padding-right: 40px;
}

.lp-checkbox {
  position: absolute;
  opacity: 0;
}
.lp-checkbox:checked + .lp-checkbox-decor::before {
  text-indent: 0;
}

.custom-input-native:checked ~ .custom-input-checkbox::after {
  text-indent: 0;
  background-image: none;
}

.ng-submitted.ng-dirty .lp-checkbox.ng-invalid + .lp-checkbox-decor::before {
  border-color: #f15959;
}

.lp-label {
  display: inline-flex;
  /* align-items: center; */
  cursor: pointer;
}

.custom-input-checkbox,
.custom-input-native:checked ~ .custom-input-checkbox,
.custom-input-checkbox:hover {
  border-width: 0;
  background: transparent none;
  box-shadow: none !important;
  width: auto;
  height: auto;
  outline: none;
  top: auto;
  margin: 0;
}

.lp-checkbox-decor::before,
.custom-input-checkbox::after {
  width: 24px;
  height: 24px;
  border-radius: 2px;
  border: solid 1px #d8d8d8;
  margin-right: 14px;
  line-height: 1;
  font-size: 24px;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  box-sizing: border-box;
  content: "\e814";
  font-family: advlp, sans-serif;
  position: relative;
  overflow: hidden;
  text-indent: 24px;
  flex: 0 0 24px;
  max-width: 24px;
}