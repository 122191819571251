@charset "UTF-8";
/* >480 - смартфоны */
/* >768 - планшеты */
/* >1024 - нетбуки, ноутбуки */
/* >1200 - десктоп */
/* >1600 - десктоп */
.lp-table {
  /* font-family: "OpenSans-light", Tahoma,"Helvetica Neue",Helvetica,Geneva,Arial,sans-serif; */
}

.lp-table__head {
  display: none;
  font-size: 14px;
  /* font-family: "OpenSans-semibold", Tahoma,"Helvetica Neue",Helvetica,Geneva,Arial,sans-serif; */
}

.lp-table__body {
  font-size: 14px;
}

/* .lp-table__row {
    margin-top: 30px;
    margin-bottom: 30px;
} */
.lp-table__header,
.lp-table__cell {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e0e0e0;
}

.lp-table__header {
  font-size: 23px;
  font-weight: bold;
}

.lp-table__cell_last:last-child {
  border-bottom-width: 0;
}

.lp-table__cell-title {
  font-weight: bold;
}

.lp-table__cell--slim {
  padding-top: 5px;
  padding-bottom: 5px;
}

.lp-table__cell--border-none {
  border-bottom-width: 0;
}

.lp-grid__row--nowrap {
  flex-wrap: nowrap !important;
  border-bottom: 1px solid #e0e0e0;
}
.lp-grid__row--nowrap .lp-table__cell,
.lp-grid__row--nowrap .lp-table__cell-grid-custom {
  border-bottom: none;
  padding: 0;
}
.lp-grid__row--nowrap .lp-grid__drag {
  padding: 16px 0;
}

@media (min-width: 64em) {
  .lp-table__row {
    margin-top: 0;
    margin-bottom: 0;
    border-bottom: 1px solid #e0e0e0;
  }
  .lp-table__header,
  .lp-table__cell {
    border-bottom-width: 0;
  }
  .lp-table__cell_last:last-child {
    border-bottom-width: 1px;
  }
  .lp-table__cell-title {
    font-weight: normal;
  }
}