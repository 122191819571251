.connector {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 500;
}
.connector__list {
  display: none;
  padding: 0;
  margin: 0;
  list-style: none;
  overflow: visible;
  flex-flow: column wrap;
  max-height: calc(100vh - 80px);
}
.connector__list-item {
  padding: 0;
  margin: 20px 5px 0;
  position: relative;
}
.connector__list-item:hover .connector__popup {
  display: block;
}
.connector__item, .connector__trigger {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 0;
}
.connector__item--custom {
  background-color: #ababab;
}
.connector__trigger {
  position: relative;
  margin: 5px 5px 0;
}
.connector__trigger-el {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.connector__trigger-close {
  display: none;
}
.connector__state {
  position: absolute;
  height: 0;
  width: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}
.connector__state:checked ~ .connector__trigger {
  margin-top: 20px;
}
.connector__state:checked ~ .connector__trigger .connector__trigger-open {
  display: none;
}
.connector__state:checked ~ .connector__trigger .connector__trigger-close {
  display: block;
}
.connector__state:checked ~ .connector__list {
  display: flex;
}
.connector__trigger, .connector__item--chat {
  background-color: #2d9ced;
}
.connector__trigger:hover, .connector__item--chat:hover {
  box-shadow: 0 0 10px 0 #2d9ced;
}
.connector__connector__trigger-el {
  position: absolute;
}
.connector__item--vkontakte {
  background-color: #597da0;
}
.connector__item--vkontakte:hover {
  box-shadow: 0 0 10px 0 #597da0;
}
.connector__item--facebook {
  background-color: #0084ff;
}
.connector__item--facebook:hover {
  box-shadow: 0 0 10px 0 #0084ff;
}
.connector__item--callback {
  background-color: #055a98;
}
.connector__item--callback:hover {
  box-shadow: 0 0 10px 0 #055a98;
}
.connector__item--whatsapp {
  background-color: #26d367;
}
.connector__item--whatsapp:hover {
  box-shadow: 0 0 10px 0 #26d367;
}
.connector__item--viber {
  background-color: #7c529e;
}
.connector__item--viber:hover {
  box-shadow: 0 0 10px 0 #7c529e;
}
.connector__item--telegram {
  background-color: #2da5e1;
}
.connector__item--telegram:hover {
  box-shadow: 0 0 10px 0 #2da5e1;
}
.connector__item--odnoklassniki {
  background-color: #ef7d01;
}
.connector__item--odnoklassniki:hover {
  box-shadow: 0 0 10px 0 #ef7d01;
}
.connector__popup {
  background: #fff;
  border-radius: 3px;
  position: absolute;
  right: 100%;
  margin-right: 10px;
  padding: 5px 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  text-align: center;
  color: #4b4f58;
}
.connector__ip-telephony--compact .connector__ip-telephony-form {
  display: none;
}
.connector__ip-telephony--full ~ .connector__popup {
  display: none !important;
}
.connector__ip-telephony-form {
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  background-color: #055a98;
  display: table;
  border-radius: 0.3125rem;
  font-size: 0.875rem;
  padding: 5px;
  max-width: calc(100vw - 10px);
  box-sizing: border-box;
  width: 450px;
  margin-right: 10px;
}
.connector__ip-telephony-cell {
  display: table-cell;
  vertical-align: middle;
  padding: 5px;
}
.connector__input {
  font-size: 0.75rem;
  padding: 0.5625rem;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px #e2e3e4 solid;
  vertical-align: middle;
  width: 100%;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  box-shadow: none;
  height: auto;
  line-height: normal;
}
.connector__button {
  display: inline-block;
  vertical-align: middle;
  -webkit-text-decoration: none;
  text-decoration: none;
  border: 0;
  text-align: center;
  border-radius: 0.3125rem;
  line-height: 1.3;
  box-sizing: border-box;
  color: #fff;
  background: #e20000;
  cursor: pointer;
}
.connector__button--small {
  font-weight: bold;
  font-size: 0.75rem;
  padding: 0.625rem;
}
.connector__button:hover {
  color: #fff;
  background: #fc0000;
  -webkit-text-decoration: none;
  text-decoration: none;
}
.connector__button:active {
  color: #fff;
  background: #c90000;
  -webkit-text-decoration: none;
  text-decoration: none;
}
.connector a.connector__item:hover {
  color: #fff;
}
.connector__ip-telephony-input {
  color: #4b4f58;
}
.connector__ip-telephony-close {
  position: absolute;
  bottom: 100%;
  left: 100%;
  font-size: 20px;
  line-height: 1;
  font-family: Arial, sans-serif;
  background-color: transparent;
  padding: 0;
  border: 0;
  color: #434142;
  cursor: pointer;
}
.connector__ip-telephony-copyright {
  position: absolute;
  top: 100%;
  font-size: 12px;
  right: 0;
}

.connector-with-bottom-panel {
  bottom: 100px !important;
}

@media (min-height: 641px) {
  .connector__item,
  .connector__trigger {
    width: 56px;
    height: 56px;
  }
}
@media (max-width: 321px) {
  .connector__item,
  .connector__trigger {
    width: 36px;
    height: 36px;
  }
  .connector__item svg,
  .connector__trigger svg {
    width: 20px;
    height: 20px;
  }
}
/* @media (min-width: 48em){
    .connector__popup{
        display: none;
    }
} */