@charset "UTF-8";
/* >480 - смартфоны */
/* >768 - планшеты */
/* >1024 - нетбуки, ноутбуки */
/* >1200 - десктоп */
/* >1600 - десктоп */
.lp-block-text-button {
  margin-top: 40px;
}

.lp-block-text--middle,
.lp-block-text--large {
  line-height: 1.5;
}

.lp-block-text-image__col {
  padding-top: 20px;
  padding-bottom: 20px;
}

.lp-block-text__header--mobile {
  margin-bottom: 40px;
}

.lp-block-text-image-carousel .slick-prev {
  left: 0;
}

.lp-block-text-image-carousel .slick-dots {
  bottom: -40px;
}

.lp-block-text-image-carousel .slick-next {
  right: 0;
}

/* .lp-block-text-header-bold {

    &__title.lp-h2 {
        font-weight: 600;
    }
} */
/* .text-header-bold {
    .lp-h2, .lp-h3 {
        font-weight: 600; так как настраивеатся
    }
} */
@media (min-width: 64em) {
  .lp-block-text-image__content {
    padding-right: 36px;
  }
  .lp-block-text-image-reverse__content {
    padding-left: 36px;
  }
  .lp-block-text-pyramid__content {
    padding-left: 56px;
  }
  .lp-block-text__header--mobile {
    margin-bottom: 0;
  }
}
@media (min-width: 75em) {
  .lp-block-text-image__content {
    padding-right: 70px;
  }
  .lp-block-text-image-reverse__content {
    padding-left: 70px;
  }
  .lp-block-text-pyramid__content {
    padding-left: 112px;
  }
}
/************** text-reviews ******************/
.text-reviews__picture-wrap {
  /* padding-top: 28px; */
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 80px;
  flex: 1 0 80px;
}

.text-reviews__picture-wrap img {
  height: 80px;
  width: 80px;
  overflow: hidden;
  border-radius: 50%;
}

.text-reviews__author {
  padding-top: 11px;
  font-size: 20px;
  line-height: 1.6;
  margin-bottom: 4px;
  text-align: center;
}

.text-reviews__caption {
  font-size: 16px;
  margin-bottom: 28px;
  line-height: 1.25;
  text-align: center;
}

.text-reviews__text {
  font-size: 16px;
  margin-bottom: 24px;
  line-height: 1.5;
  text-align: center;
  font-weight: 400;
}

@media (min-width: 48em) {
  .text-reviews .text-reviews___picture {
    height: 80px;
    width: 80px;
  }
  .text-reviews__author {
    padding-top: 11px;
    font-size: 20px;
    line-height: 1.6;
  }
  .text-reviews__caption {
    font-size: 16px;
    margin-bottom: 24px;
    line-height: 1.75;
    text-align: left;
  }
  .text-reviews__text {
    font-size: 16px;
    margin-bottom: 24px;
    line-height: 1.5;
    text-align: left;
  }
}
.lp-block-text-coupon__block {
  border-radius: 3px;
  border: dashed 1px rgb(200, 200, 200);
  padding: 28px 30px;
  line-height: 1.7;
}

.lp-block-text-coupon__coupon {
  font-size: 22px;
}

@media (min-width: 48em) {
  .lp-block-text-coupon__coupon {
    font-size: 36px;
  }
}
.lp-block-text-thanks__content,
.lp-block-text-paragraph__content {
  padding: 20px;
}

.lp-block-text-thanks__header {
  font-size: 28px;
  font-weight: 600;
}

.lp-block-text-paragraph .lp-h2,
.lp-block-text-paragraph .lp-h3 {
  margin-bottom: 20px;
}

.lp-block-text-paragraph__content {
  line-height: 1.5;
}

@media (min-width: 75em) {
  .text-reviews .text-reviews___picture {
    height: 100px;
    width: 100px;
  }
  .text-reviews__author {
    padding-top: 24px;
    font-size: 24px;
    margin-bottom: 4px;
    line-height: 1.33;
  }
  .text-reviews__caption {
    font-size: 18px;
    margin-bottom: 24px;
    line-height: 1.56;
  }
  .text-reviews__text {
    font-size: 18px;
    margin-bottom: 24px;
    line-height: 1.56;
  }
  .lp-block-text-thanks__content,
  .lp-block-text-paragraph__content {
    padding: 60px 100px;
  }
}
/***********************************************   */
.lp-block-text-video-reverse__inner,
.lp-block-text-video__inner {
  padding: 12px 20px;
  margin-bottom: 20px;
}

.lp-block-text-video-reverse__video {
  margin-bottom: 20px;
}

.lp-block-text-video__inner {
  margin-bottom: 20px;
}

@media (min-width: 64em) {
  .lp-block-text-video-reverse__inner,
  .lp-block-text-video__inner {
    padding: 32px 40px;
  }
  .lp-block-text-video-reverse__video {
    margin-bottom: 0;
  }
  .lp-block-text-video__inner {
    margin-bottom: 0;
  }
}
.lp-block-title-left-text-right__content {
  padding-left: 0;
}

@media (min-width: 48em) {
  .lp-block-title-left-text-right__content {
    padding-left: 30px;
  }
}