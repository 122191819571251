@charset "UTF-8";
/* >480 - смартфоны */
/* >768 - планшеты */
/* >1024 - нетбуки, ноутбуки */
/* >1200 - десктоп */
/* >1600 - десктоп */
.lp-block-characteristics__category {
  font-weight: normal;
  margin-bottom: 16px;
  text-align: left;
}

.lp-block-characteristics__cells-wrap {
  align-items: baseline;
}

.lp-block-characteristics__cell {
  padding-top: 12px;
  padding-bottom: 12px;
  font-weight: normal;
}
.lp-block-characteristics__cell--p-l-md {
  padding-left: 20px;
}

.lp-block-characteristics__block {
  margin-top: 30px;
  /* margin-bottom: 30px; */
  text-align: left;
}
.lp-block-characteristics__block:first-child {
  margin-top: 0;
}
.lp-block-characteristics__block:last-child {
  margin-bottom: 0;
}

.lp-block-characteristics__subblock--border:first-child {
  border-top-width: 1px;
  border-top-style: solid;
}

.lp-block-characteristics__subblock--border {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

/* .lp-block-characteristics__subblock--bg-alt:nth-child(2n + 1) {
    background-color: #f5f5f5;
    color: #000;
} */
.lp-block-characteristics__cell--border-r:first-child {
  border-right-width: 1px;
  border-right-style: solid;
}

@media (min-width: 48em) {
  .lp-block-characteristics__cell--p-l-md {
    padding-left: 34px;
  }
}