.custom-input-text {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.custom-input-text__indent {
  padding-left: 23px;
}

.custom-input-checkbox,
.custom-input-radio {
  flex-shrink: 0;
  cursor: pointer;
  position: relative;
  top: 2px;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin: 0 0.5rem 0 0;
  box-shadow: 0 0 0.125rem #818181;
  border: 1px #818181 solid;
  border-bottom-color: #2d2d2d;
  background: linear-gradient(to bottom, rgb(253, 253, 253) 0%, rgb(243, 243, 243) 11%, rgb(235, 235, 235) 33%, rgb(220, 220, 220) 44%, rgb(230, 230, 230) 67%, rgb(240, 240, 240) 78%, rgb(246, 246, 246) 89%, rgb(255, 255, 255) 100%);
}
.custom-input-checkbox:hover,
.custom-input-radio:hover {
  background: linear-gradient(to bottom, rgb(255, 255, 255) 0%, rgb(246, 246, 246) 11%, rgb(240, 240, 240) 22%, rgb(230, 230, 230) 33%, rgb(220, 220, 220) 56%, rgb(235, 235, 235) 67%, rgb(243, 243, 243) 89%, rgb(253, 253, 253) 100%);
}

.custom-input-checkbox {
  border-radius: 0.1875rem;
}

.custom-input-checkbox ~ .custom-input-text {
  font-size: 13px;
}

.custom-input-checkbox__abs {
  position: absolute;
  left: 0;
  top: 4px;
}

.custom-input-radio {
  border-radius: 50%;
}

.custom-input-checkbox::after {
  content: "";
  position: absolute;
  width: 0.8125rem;
  height: 0.8125rem;
  background-repeat: no-repeat;
  left: 0.0625rem;
  top: -0.125rem;
}

.custom-input-radio::after {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.custom-input-native {
  opacity: 0;
  position: absolute;
}
.custom-input-native:checked ~ .custom-input-checkbox, .custom-input-native:checked ~ .custom-input-radio {
  background: linear-gradient(to bottom, rgb(66, 80, 155) 0%, rgb(148, 163, 202) 3%, rgb(233, 248, 255) 5%, rgb(230, 243, 249) 8%, rgb(218, 234, 247) 11%, rgb(186, 223, 242) 16%, rgb(171, 212, 242) 19%, rgb(153, 206, 238) 22%, rgb(128, 197, 238) 41%, rgb(121, 196, 235) 43%, rgb(119, 189, 241) 46%, rgb(51, 179, 226) 49%, rgb(60, 179, 235) 51%, rgb(65, 179, 233) 54%, rgb(81, 182, 234) 57%, rgb(81, 186, 234) 59%, rgb(100, 192, 243) 65%, rgb(102, 193, 238) 68%, rgb(127, 204, 250) 78%, rgb(141, 215, 254) 81%, rgb(167, 235, 254) 86%, rgb(176, 246, 255) 89%, rgb(183, 251, 254) 92%, rgb(184, 252, 255) 95%, rgb(181, 247, 255) 97%, rgb(171, 236, 254) 100%);
}
.custom-input-native:disabled ~ .custom-input-text {
  color: #94969b;
  pointer-events: none;
}
.custom-input-native:disabled ~ .custom-input-checkbox {
  opacity: 0.5;
  pointer-events: none;
}
.custom-input-native:focus ~ .custom-input-checkbox, .custom-input-native:focus ~ .custom-input-radio {
  box-shadow: 0 0 5px 0 rgb(66, 80, 155);
}

.custom-input-native:checked ~ .custom-input-checkbox::after {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMTEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBvbHlnb24gcG9pbnRzPSIwLDUgNCwxMSA1LDExIDExLDAgOSwwIDUsOCA0LDggMyw1IiBmaWxsPSJyZWQ7Ij48L3BvbHlnb24+PC9zdmc+");
}

.custom-input-native:checked ~ .custom-input-radio::after {
  background-color: #000;
}

.custom-iphone-checkbox {
  border-radius: 0.1875rem;
  cursor: pointer;
  display: inline-block;
  height: 1.125rem;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
  width: 3.4375rem;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  font-size: 0.625rem;
  transform: translateZ(0);
}
.custom-iphone-checkbox input {
  display: none;
}
.custom-iphone-checkbox input:checked + .custom-iphone-checkbox-slider {
  left: 0;
}

.custom-iphone-checkbox-slider {
  left: -50%;
  position: absolute;
  top: 0;
  bottom: 0;
  transition: left 0.25s ease-out;
  border-radius: 0.1875rem;
}

.custom-iphone-checkbox-on,
.custom-iphone-checkbox-block,
.custom-iphone-checkbox-off {
  display: block;
  font-weight: bold;
  height: 100%;
  line-height: 1.125rem;
  position: absolute;
  text-align: center;
  top: 0;
  box-sizing: border-box;
}

.custom-iphone-checkbox-on {
  background-color: rgb(6, 98, 193);
  color: white;
  left: 0;
  width: 1.84375rem;
  padding-right: 0.125rem;
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
}

.custom-iphone-checkbox-off {
  background-color: #252729;
  color: #8b8b8b;
  padding-left: 0.125rem;
  left: 3.3125rem;
  width: 1.71875rem;
  border-top-right-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem;
}

.custom-iphone-checkbox-block {
  background-color: #5a5d60;
  border-radius: 0.1875rem;
  height: 100%;
  left: 1.71875rem;
  width: 1.71875rem;
}

.btn-ghost {
  border: 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto 1.25rem auto 0;
  width: 13px;
  height: 13px;
  color: #adadad;
}
.btn-ghost:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  color: #adadad;
}

.select-custom {
  position: relative;
  display: inline-block;
  width: 100%;
  color: #fff;
  vertical-align: middle;
}
.select-custom::after {
  right: 0.625rem;
  pointer-events: none;
}
.select-custom select,
.select-custom .form-select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  /* do not forget set background color to select by color-sheme */
  /* border: none; */
  border-color: transparent;
  padding: 0.5rem 1.875rem 0.5rem 0.9375rem;
  border-radius: 0.3125rem;
  width: 100%;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
}
.select-custom select:hover,
.select-custom .form-select:hover {
  /* &:focus */
  /* border: none; */
  outline: none;
}
.select-custom select::-ms-expand,
.select-custom .form-select::-ms-expand {
  display: none;
}
.select-custom select option,
.select-custom .form-select option {
  background-color: #fff;
  color: #333;
  border: none;
}
.select-custom select option:disabled,
.select-custom .form-select option:disabled {
  color: #4b4f58;
  background-color: #d1d1d1;
}
.select-custom--slim select,
.select-custom--slim .form-select {
  padding: 0 1.875rem 0 0.9375rem;
  height: 1.625rem;
}
.select-custom--fat select,
.select-custom--fat .form-select {
  padding-top: 12px;
  padding-bottom: 12px;
}
.select-custom--shrink {
  width: inherit;
  max-width: 100%;
}

.sizes-select-custom::after,
.color-select-custom::after {
  color: #252729;
}