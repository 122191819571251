@charset "UTF-8";
/* >480 - смартфоны */
/* >768 - планшеты */
/* >1024 - нетбуки, ноутбуки */
/* >1200 - десктоп */
/* >1600 - десктоп */
.svg-wrap:hover .svg-fill-custom {
  fill: rgba(255, 255, 255, 0.1);
}

.lp-block-cover {
  background-size: cover;
  position: relative;
  background-repeat: no-repeat !important;
}

.lp-cover-header {
  font-size: 32px;
  font-weight: 300;
  line-height: 1.25;
  margin-bottom: 20px;
  text-align: center;
}

.lp-cover-text {
  line-height: 1.5;
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 43px;
}

.lp-cover-btn-wrap {
  text-align: center;
  margin-bottom: 20px;
}

.lp-cover-btn-wrap-addition {
  margin-left: 0;
}

.lp-cover-btn-wrap.lp-cover-btn-wrap-inline .lp-btn {
  display: flex;
  align-items: center;
}

.lp-cover-btn-wrap.lp-cover-btn-wrap-inline {
  display: flex;
  justify-content: center;
}

@media (min-width: 48em) {
  .lp-cover-header {
    font-size: 52px;
    line-height: 1;
    margin-bottom: 28px;
  }
  .lp-cover-btn-wrap.lp-cover-btn-wrap-inline {
    display: inline-flex;
  }
  .lp-cover-btn-wrap-addition {
    margin-left: 20px;
  }
  .lp-cover-text {
    line-height: 1.4;
    margin-bottom: 48px;
  }
}
@media (min-width: 75em) {
  .lp-cover-header {
    font-size: 68px;
    line-height: 1;
    margin-bottom: 32px;
  }
  .lp-cover-text {
    line-height: 1.5;
    font-size: 24px;
    margin-bottom: 44px;
  }
}
/******** CoverVideo *********/
.lp-block-cover-video-header {
  font-size: 32px;
  line-height: 1.04;
  margin-bottom: 22px;
  text-align: center;
}

.lp-block-cover-video-text {
  line-height: 1.3;
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 40px;
}

@media (min-width: 48em) {
  .lp-block-cover-video-header {
    font-size: 40px;
    line-height: 1;
  }
  .lp-block-cover-video-text {
    line-height: 1.25;
    font-size: 15px;
  }
}
@media (min-width: 75em) {
  .lp-block-cover-video-header {
    font-size: 48px;
    font-weight: 300;
    line-height: 1;
    margin-bottom: 24px;
  }
  .lp-block-cover-video-text {
    font-size: 20px;
    font-weight: 300;
    line-height: 1.4;
    margin-bottom: 65px;
  }
}
/***************************   */
/*********** CoverVideoLeft *************/
.lp-block-cover-video-left-header {
  font-size: 32px;
  line-height: 1.25;
  text-align: left;
  margin-bottom: 16px;
  font-weight: 300;
}

.lp-block-cover-video-left-text {
  line-height: 1.5;
  font-size: 16px;
  text-align: left;
  margin-bottom: 20px;
  font-weight: 300;
}

.lp-block-cover-video-left .iframe-responsive__item {
  max-height: none !important;
  max-width: none !important;
}

.lp-block-cover-video-left-text-wrap {
  margin-bottom: 76px;
}

@media (min-width: 48em) {
  .lp-block-cover-video-left-header {
    font-size: 40px;
    line-height: 1;
    text-align: left;
    margin-bottom: 20px;
  }
  .lp-block-cover-video-left-text {
    font-size: 20px;
    line-height: 1.4;
    text-align: left;
    margin-bottom: 36px;
  }
  .lp-block-cover-video-left .lp-cover-btn-wrap {
    text-align: left;
  }
  .lp-block-cover-video-left-text-wrap {
    margin-bottom: 0;
  }
}
@media (min-width: 75em) {
  .lp-block-cover-video-left-header {
    font-size: 48px;
    line-height: 1;
    text-align: left;
    margin-bottom: 24px;
  }
  .lp-block-cover-video-left-text {
    font-size: 24px;
    line-height: 1.33;
    text-align: left;
    margin-bottom: 35px;
  }
}
/****************************   */
/* .lp-block-cover-video-left iframe-responsive iframe {
    display: block;
    box-shadow: 0 13px 45px -2px rgba(0, 0, 0, 0.5);
} */
/*********** CoverTextLeft *************/
.lp-block-cover-text-left-header {
  font-size: 32px;
  font-weight: 100;
  line-height: 1.25;
  margin-bottom: 16px;
}

.lp-block-cover-text-left-text {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 29px;
  font-weight: 100;
}

.lp-block-cover-text-left .lp-cover-btn-wrap {
  margin-top: 78px;
}

@media (min-width: 48em) {
  .lp-block-cover-text-left-header {
    font-size: 40px;
    line-height: 1.2;
    margin-bottom: 24px;
  }
  .lp-block-cover-text-left-text {
    line-height: 1.4;
    font-size: 20px;
    margin-bottom: 32px;
  }
}
@media (min-width: 75em) {
  .lp-block-cover-text-left-header {
    font-size: 48px;
    line-height: 1;
    margin-bottom: 24px;
  }
  .lp-block-cover-text-left-text {
    font-size: 24px;
    line-height: 1.33;
    margin-bottom: 48px;
  }
}
/***************************************   */
/****  cover-text-form-slim */
.lp-block-cover-text-form-slim__form-wrap {
  padding: 20px 27px 27px;
  border-radius: 5px;
}

/*************************   */
/********* CoverFormVidoLeft ***********/
/* .cover-form-video-left .lp-form {
    background-color: #fff;
} */
.lp-block-cover-form-video-left-header {
  /* font-size: 43px;
  line-height: 1.04; */
  text-align: center;
  /* margin-bottom: 22px; */
}

.lp-block-cover-form-video-left-text {
  /* line-height: 1.3;
  font-size: 16px; */
  text-align: center;
  /* margin-bottom: 22px; */
}

@media (min-width: 48em) {
  .lp-block-cover-form-video-left-header {
    /* font-size: 54px;
    line-height: 1.25; */
    /* margin-bottom: 20px; */
    text-align: center;
  }
  .lp-block-cover-form-video-left-text {
    /* line-height: 1.25;
    font-size: 20px; */
    /* margin-bottom: 63px; */
    text-align: center;
  }
}
@media (min-width: 75em) {
  .lp-block-cover-form-video-left-header {
    /* font-size: 63px;
    line-height: 1.03; */
    /* margin-bottom: 20px; */
  }
  .lp-block-cover-form-video-left-text {
    /* font-size: 29px;
    line-height: 1.34; */
    /* margin-bottom: 63px; */
  }
}
/*************************************   */
/************ CoverForm **********/
.lp-cover-form-header {
  font-size: 35px;
}

/*******************************   */
.lp-cover-form-column {
  margin-bottom: 23px;
}

.cover-form-video-left-light .lp-form {
  padding-top: 0;
}

/**************** Анимация для стрелки ********************/
.icon-lp-down-open {
  animation: arrow-bottom 2s infinite ease;
}

@keyframes arrow-bottom {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
/************************************************   */
/******** Covers text bottom *******/
/* .cover-text-left-bottom__header {
    font-size: 43px;
    position: relative;
    line-height: 1.04;
    margin-bottom: 22px;
    font-weight: 100;
}

.cover-text-left-bottom__text {
    line-height: 1.3;
    font-size: 16px;
} */
/* @media (min-width: 48em) {

    .cover-text-left-bottom__header {
        font-size: 37px;
        line-height: 1;
    }

    .cover-text-left-bottom__text {
        line-height: 1.2;
        font-size: 20px;
    }

}

@media (min-width: 75em) {
    .cover-text-left-bottom__header {
        font-size: 52px;
        line-height: 1;
    }

    .cover-text-left-bottom__text {
        line-height: 1.2;
        font-size: 24px;
    }
} */
/********************************   */
/********** lp-block-cover-text-left-modal-video ******************/
/* .lp-block-cover-text-left-modal-video-header {
    font-weight: 600;
    font-size: 28px;
    line-height: 1.29;
    margin-bottom: 16px;
} */
/* .lp-block-cover-text-left-modal-video-text {
    font-weight: 300;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 64px;
} */
@media (min-width: 48em) {
  .lp-block-cover-text-left-modal-video-header {
    font-weight: 600;
    /* font-size: 48px;
    line-height: 1.2;
    margin-bottom: 21px; */
  }
  /* .lp-block-cover-text-left-modal-video-text {
      font-weight: 300;
      font-size: 20px;
      line-height: 1.4;
      margin-bottom: 44px;
  } */
}
@media (min-width: 75em) {
  .lp-block-cover-text-left-modal-video-header {
    font-weight: 600;
    /* font-size: 48px;
    line-height: 1;
    margin-bottom: 24px; */
  }
  /* .lp-block-cover-text-left-modal-video-text {
      font-weight: 300;
      font-size: 24px;
      line-height: 1.33;
      margin-bottom: 44px;
  } */
}
/*****************************************************************   */
.cover-image-text__picture-wrap {
  margin-bottom: 20px;
}

.cover-carousel {
  padding: 0 !important;
  box-shadow: none !important;
}
.cover-carousel__items-wrap {
  white-space: nowrap;
}
.cover-carousel__item {
  background-position: center center;
  background-attachment: scroll;
  height: auto;
  position: relative;
  display: inline-block;
  white-space: normal;
}
.cover-carousel__item-inner {
  position: relative;
  z-index: 2;
  padding-bottom: 65px;
}
.cover-carousel__image {
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
  top: 0;
  background-size: cover;
  background-position: center center;
}
.cover-carousel .slick-slide {
  position: relative;
}
.cover-carousel .slick-next {
  height: 38px;
  width: 30px;
  right: 4px;
  padding: 10px;
}
.cover-carousel .slick-prev {
  height: 38px;
  width: 30px;
  left: 4px;
  padding: 10px;
}
.cover-carousel .slick-arrow {
  z-index: 1;
}
.cover-carousel .slick-dots {
  bottom: 20px;
}
.cover-carousel .slick-dotted.slick-slider {
  margin-bottom: 0;
}

@media (min-width: 48em) {
  .cover-image-text__picture-wrap {
    margin-bottom: 0;
  }
  .cover-carousel .slick-next {
    right: 25px;
  }
  .cover-carousel .slick-prev {
    left: 25px;
  }
}
/* cover-form-center */
.cover-form-center__wrap {
  width: 100%;
  box-sizing: border-box;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.cover-form-center__rounded-wrap {
  padding: 25px 4% 40px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.cover-form-center__width-block {
  position: relative;
  min-width: 100%;
}
.cover-form-center__prop {
  margin-top: 0;
}
.cover-form-center .only-one-field .lp-form__agreement {
  order: 3;
}
.cover-form-center .only-one-field .lp-form__body {
  justify-content: center;
}
.cover-form-center .only-one-field .lp-form__submit-block,
.cover-form-center .only-one-field .lp-form__field {
  flex: 0 1 auto;
  padding-left: 8px;
  padding-right: 8px;
}
.cover-form-center .lp-form__body--vertical {
  margin-top: 0;
}
.cover-form-center__subtitle {
  /* font-size: 22px; */
}
.cover-form-center .scroll-to-block-trigger {
  bottom: 0;
}
.cover-form-center__text {
  margin-bottom: 15px;
}
.cover-form-center__text--height-limiter {
  max-height: 115px;
  overflow: hidden;
}

@media (min-width: 550px) {
  .cover-form-center__rounded-wrap {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    padding: 0 10%;
  }
  .cover-form-center__prop {
    margin-top: 100%;
  }
  .cover-form-center__wrap {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .cover-form-center__width-block {
    position: relative;
    min-width: 544px;
    box-sizing: border-box;
  }
  .cover-form-center__text {
    margin-bottom: 20px;
  }
}
@media (min-width: 64em) {
  .cover-form-center__subtitle {
    /* font-size: 26px; */
  }
}
@media (min-width: 75em) {
  .cover-form-center__subtitle {
    /* font-size: 30px; */
  }
}
/****************   */
/* VideoBackground */
.video-background {
  /* &__text-container {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center;
      width: 100%;
      height: auto;
      margin: auto;
  } */
}
.video-background__video-container {
  /* position: relative; */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0;
}
.video-background__overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.video-background__text-container {
  position: relative;
  z-index: 1;
}
.video-background__image-inner {
  margin-bottom: 30px;
}

.lp-block-video-background {
  position: relative;
  /* video, */
}
.lp-block-video-background .video-background__video-container > div,
.lp-block-video-background subblock-inplace > div,
.lp-block-video-background subblock-inplace {
  width: 100%;
  height: 100%;
}
.lp-block-video-background .embed-container iframe,
.lp-block-video-background iframe {
  box-sizing: border-box;
  height: 56.25vh;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  width: 177.7778vw;
}
.lp-block-video-background .iframe-responsive__container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.lp-block-video-background .iframe-responsive__container-upload .iframe-responsive__container {
  position: static;
  padding-bottom: 0;
  height: auto;
  overflow: hidden;
  max-width: 100%;
  min-width: 100%;
  transform: translate(0, 0);
}

.block-full-height.lp-block-video-background {
  /* video, */
}
.block-full-height.lp-block-video-background .embed-container {
  min-height: 100vh;
  box-sizing: border-box;
}
.block-full-height.lp-block-video-background iframe {
  box-sizing: border-box;
  height: 56.25vw;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  width: 177.7778vh;
}

@media (min-width: 48em) {
  .video-background__image-inner {
    margin-bottom: 45px;
  }
}
/*********************   */