@charset "UTF-8";
/* >480 - смартфоны */
/* >768 - планшеты */
/* >1024 - нетбуки, ноутбуки */
/* >1200 - десктоп */
/* >1600 - десктоп */
.lp-menu-header {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  justify-content: center;
}

.lp-menu-header-align--left .lp-menu-header {
  justify-content: flex-start;
}

.lp-menu-header__logo {
  margin: 30px 0;
  text-align: center;
}

.lp-menu-header__item {
  box-sizing: border-box;
}

.lp-menu-header__item,
.lp-menu-header--burger .lp-menu-header__item,
.lp-menu-header--burger-right .lp-menu-header__item {
  padding: 10px 20px;
  margin-bottom: 20px;
}

.lp-menu-header__link {
  -webkit-text-decoration: none;
  text-decoration: none;
  color: currentcolor;
  display: inline-block;
}
.lp-menu-header__link:hover {
  -webkit-text-decoration: underline;
  text-decoration: underline;
  opacity: 0.8;
}

.lp-menu-header__label,
.lp-menu-header__close {
  cursor: pointer;
  border: 0;
  background-color: transparent;
}

.lp-header .lp-menu-header__label,
.lp-header .lp-menu-header__close {
  color: currentcolor;
}

.lp-menu-header__label {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 32px;
}

.lp-menu-header__label-obj {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lp-menu-header-container {
  display: none;
  font-size: 16px;
  position: fixed;
  top: 0;
  left: 0;
  transform: translate3d(0, 0, 0);
  height: 100vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  z-index: 100;
  width: 100%;
  pointer-events: auto;
  /* Avoid Chrome to see Safari hack */
}
.lp-menu-header-container--open {
  display: block;
  z-index: 251;
}
.lp-menu-header-container--open .lp-menu-header__close {
  display: block;
}
.lp-menu-header-container--open ~ .lp-menu-header__over {
  display: block;
}
.lp-menu-header-container--open .lp-menu-header__logo {
  display: block;
}

@supports (-webkit-touch-callout: none) {
  .lp-menu-header-container {
    /* The hack for Safari */
    height: -webkit-fill-available;
    height: -moz-available;
    height: stretch;
  }
}
.lp-menu-header__close {
  position: fixed;
  top: 30px;
  right: 25px;
  z-index: 200;
  display: none;
}

.lp-menu-header__over {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
  opacity: 0.5;
  width: 100%;
}

.lp-menu-header__contacts-item {
  margin-bottom: 10px;
}

.lp-menu-header__burger-container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 48px;
  left: 26px;
}

.lp-menu-header__tile-submenu {
  padding-top: 20px;
  min-width: 150px;
}

.lp-menu-header--items-space .lp-menu-header__item {
  padding-left: 15px;
  padding-right: 15px;
}

.lp-menu-header--burger-right {
  right: 0;
  left: auto;
}
.lp-menu-header--burger-right .lp-menu-header-container {
  left: auto;
  right: 0;
}

.lp-menu-header--burger .lp-menu-header-container,
.lp-menu-header--burger-right .lp-menu-header-container {
  text-align: center;
}

@media (min-width: 64em) {
  .lp-menu-header--burger .lp-menu-header-container,
  .lp-menu-header--burger-right .lp-menu-header-container {
    max-width: 600px;
  }
  .lp-menu-header--burger .lp-menu-header__label,
  .lp-menu-header--burger-right .lp-menu-header__label {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
  .lp-menu-header--burger .lp-menu-header__label-obj,
  .lp-menu-header--burger-right .lp-menu-header__label-obj {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
  }
  .lp-menu-header--burger .lp-menu-header-container--open,
  .lp-menu-header--burger-right .lp-menu-header-container--open {
    display: block;
  }
  .lp-menu-header--burger .lp-menu-header-container--open .lp-menu-header__close,
  .lp-menu-header--burger-right .lp-menu-header-container--open .lp-menu-header__close {
    display: block;
  }
  .lp-menu-header--burger .lp-menu-header-container--open ~ .lp-menu-header__over,
  .lp-menu-header--burger-right .lp-menu-header-container--open ~ .lp-menu-header__over {
    display: block;
  }
  .lp-menu-header--burger .lp-menu-header-container--open .lp-menu-header__logo,
  .lp-menu-header--burger-right .lp-menu-header-container--open .lp-menu-header__logo {
    display: block;
  }
  .lp-menu-header__burger-container {
    position: relative;
    width: auto;
    left: auto;
  }
  .lp-menu-header__item {
    margin-bottom: 0;
  }
  .lp-menu-header--transform .lp-menu-header__label,
  .lp-menu-header--transform .lp-menu-header__close {
    display: none;
  }
  .lp-menu-header--transform .lp-menu-header {
    flex-direction: row;
  }
  .lp-menu-header--transform .lp-menu-header-container {
    height: auto;
    display: flex !important;
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    background: none;
    justify-content: center;
    overflow: visible;
  }
  .lp-menu-header__link {
    white-space: nowrap;
  }
  .lp-menu-header-container--open {
    display: none;
  }
  .lp-menu-header-container--open .lp-menu-header__close {
    display: none;
  }
  .lp-menu-header-container--open ~ .lp-menu-header__over {
    display: none;
  }
  .lp-menu-header-container--open .lp-menu-header__logo {
    display: block;
  }
  .lp-menu-header--burger-right {
    text-align: right;
    right: auto;
  }
  .lp-menu-header_right .lp-menu-header {
    justify-content: flex-end;
  }
  .lp-menu-header--twice {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 0;
  }
  .lp-menu-header__divider {
    height: 1px;
  }
}
.lp-menu--static {
  flex-direction: row;
  justify-content: flex-start;
}

.lp-menu__submenu--static {
  border: 0;
  margin-top: 0;
}

.lp-menu__item--static {
  flex-basis: 25%;
  max-width: 25%;
  min-width: 286px;
  padding-right: 10px;
}
.lp-menu__item--static .lp-menu-header__link {
  padding-bottom: 10px;
}

.lp-menu__submenu-link--static,
.lp-menu__link--static {
  white-space: normal;
}