@charset "UTF-8";
/* >480 - смартфоны */
/* >768 - планшеты */
/* >1024 - нетбуки, ноутбуки */
/* >1200 - десктоп */
/* >1600 - десктоп */
.lp-partner-logos-wrap {
  margin-left: -20px;
  margin-right: -20px;
}

.partners-logo-carousel__carousel-wrap {
  padding: 0;
}

.partners-logo-carousel__item {
  position: relative;
  padding: 0;
  box-sizing: border-box;
  text-align: center;
  float: left;
}

.partners-logo-carousel--preview-admin {
  display: flex !important;
}

.lp-partner-logo .gallery-remove {
  top: 0;
}

.lp-partner-logo-block__item-container {
  margin-left: -12px;
  margin-right: -12px;
}

.lp-partner-logo-block__item-wrap {
  flex-basis: 180px;
  max-width: 180px;
  padding: 17px;
  box-sizing: border-box;
}

.lp-partner-logo-block__item {
  position: relative;
}

.partners-logo-carousel-block .slick-track {
  display: flex;
  align-items: center;
}

@media (min-width: 48em) {
  .partners-logo-carousel__carousel-wrap {
    padding: 0 22px;
  }
  .partners-logo-carousel__item {
    padding: 24px;
  }
}