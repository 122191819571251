@charset "UTF-8";
/* >480 - смартфоны */
/* >768 - планшеты */
/* >1024 - нетбуки, ноутбуки */
/* >1200 - десктоп */
/* >1600 - десктоп */
.lp-block-booking-resources__row {
  margin-left: -14px;
  margin-right: -14px;
}

.lp-block-booking-resources__item,
.lp-block-booking-resources__item--header {
  padding-left: 14px;
  padding-right: 14px;
}

.lp-block-booking-resources__item {
  padding-bottom: 14px;
}

.lp-block-booking-resources__item:nth-child(n+4) {
  padding-top: 14px;
}

.lp-block-booking-resources__content {
  padding: 24px;
  text-align: center;
}

.lp-block-booking-resources__text {
  margin-top: 8px;
}

.lp-modal-booking {
  width: 700px;
}

.lp-modal-booking-col {
  margin-bottom: 20px;
}

.lp-modal-booking-time-btn {
  background-color: transparent;
  padding: 1px 5px;
  border-radius: 3px;
  border-width: 1px;
}

.modal-content .lp-btn:disabled,
.lp-modal-booking-time-btn:disabled {
  cursor: default;
  color: #fff;
  border-color: #e5e5e5;
  background-color: rgb(221, 221, 221);
}

.lp-modal-booking-btn-group .lp-modal-booking-time-btn {
  margin: 5px;
}

.lp-modal-booking-no-time {
  color: #dd0e20;
}

.lp-modal-booking-service-img {
  max-width: 50px;
}

.lp-modal-booking .lp-accordion__trigger {
  background-color: rgb(248, 248, 248);
  border: 1px solid #eee;
}

.lp-modal-booking .lp-accordion__content {
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
  position: relative;
  overflow-x: auto;
}

.lp-modal-booking .lp-accordion__content-list > li {
  position: relative;
}

.modal-booking__checkbox {
  position: absolute;
  top: 24px;
}

.modal-booking__checkbox + span {
  padding-left: 36px;
}

@media (min-width: 48em) {
  .modal-booking__checkbox + span {
    padding-left: 60px;
  }
}
.modal-booking-form__subtitle {
  margin-bottom: 20px;
}

.lp-modal-booking .flatpickr-calendar {
  margin: 0 auto;
}

.modal-booking-form__time {
  margin-top: 10px;
}

.modal-booking-form__change-time {
  margin-left: 5px;
}

.booking-services__content-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.booking-services__content-item {
  margin: 0;
  padding: 24px 20px 24px 0;
}

.booking-services__row-info {
  display: flex;
  justify-content: space-between;
}

.booking-services__price {
  font-weight: 600;
  white-space: nowrap;
  padding-left: 30px;
}

.booking-services__content-divider {
  height: 1px;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
  background-color: rgb(226, 226, 226);
}

.booking-services__name {
  font-weight: bold;
}

.booking-accordion__name {
  padding-right: 10px;
}

.booking-accordion__part--left {
  padding-right: 18px;
  flex-grow: 1;
}

@media (min-width: 48em) {
  .booking-accordion__part--left {
    padding-right: 30px;
  }
}
.booking-accordion__part--right {
  min-width: 90px;
}

/* .lp-modal-booking .booking-accordion__name,
.lp-modal-booking .booking-accordion__time {
    flex-basis: auto;
}

.lp-modal-booking .booking-accordion__part--left {
    flex-grow: 1;
}

.lp-modal-booking .lp-block-services-accordion__price {
    width: 100%;
    max-width: 125px;
    text-align: right;
}


@media(min-width: 48em) {
    .lp-modal-booking .booking-accordion__name {
        flex-basis: 60%;
        max-width: 60%;
    }

    .lp-modal-booking .booking-accordion__time {
        flex-basis: 40%;
        max-width: 40%;
    }

    .booking-accordion__part--left {
        flex-grow: 1;
    }
} */
modal-booking,
modal-booking-services {
  display: inline-block;
}