@charset "UTF-8";
/* >480 - смартфоны */
/* >768 - планшеты */
/* >1024 - нетбуки, ноутбуки */
/* >1200 - десктоп */
/* >1600 - десктоп */
.lp-block-columns-text {
  line-height: 1.5;
}

.lp-block-columns-button {
  margin-top: 40px;
}

.lp-block-columns-header {
  margin-bottom: 24px;
}

.lp-block-columns-two__item {
  padding-top: 16px;
  padding-bottom: 16px;
}

.lp-block-video-columns__header,
.lp-block-columns-with-picture__header,
.lp-block-columns-three__header {
  font-size: 22px;
  margin-bottom: 16px;
}
.lp-block-video-columns__item,
.lp-block-columns-with-picture__item,
.lp-block-columns-three__item {
  margin-bottom: 40px;
}
.lp-block-video-columns__item-inner,
.lp-block-columns-with-picture__item-inner,
.lp-block-columns-three__item-inner {
  border-radius: 5px;
}
.lp-block-video-columns__item-inner--paddings,
.lp-block-columns-with-picture__item-inner--paddings,
.lp-block-columns-three__item-inner--paddings {
  padding: 20px;
}
.lp-block-video-columns__icon,
.lp-block-columns-with-picture__icon,
.lp-block-columns-three__icon {
  margin-bottom: 20px;
}
.lp-block-video-columns__icon svg,
.lp-block-columns-with-picture__icon svg,
.lp-block-columns-three__icon svg {
  line-height: 1;
  font-size: 64px;
}

.lp-block-video-columns__item-inner {
  padding: 0;
}
.lp-block-video-columns__text-inner {
  padding: 10px 5px 25px;
}
.lp-block-video-columns__item {
  margin-bottom: 20px;
}
.lp-block-video-columns .lp-btn--link {
  padding: 0;
}
.lp-block-video-columns .button-video-modal-trigger {
  flex-grow: 1;
}

.lp-block-columns-three-icons__icon {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.lp-block-columns-three-icons__icon svg {
  line-height: 1;
  font-size: 64px;
}

.lp-block-columns-four__header {
  font-size: 30px;
  font-weight: 600;
}
.lp-block-columns-four__item {
  margin-bottom: 40px;
}

.lp-block-columns-faq__item,
.lp-block-columns-icon-title__item {
  margin-bottom: 30px;
}
.lp-block-columns-faq__header,
.lp-block-columns-icon-title__header {
  display: flex;
  font-size: 18px;
  align-items: center;
}
.lp-block-columns-faq__icon,
.lp-block-columns-icon-title__icon {
  padding-right: 15px;
  text-align: center;
}

.lp-block-columns-faq__item {
  display: flex;
}
.lp-block-columns-faq__header {
  margin-bottom: 10px;
}
.lp-block-columns-faq__title {
  font-size: 24px;
  margin-bottom: 30px;
}
.lp-block-columns-faq__icon {
  height: 30px;
  width: 38px;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}
.lp-block-columns-faq__icon svg {
  font-size: 30px;
}
.lp-block-columns-faq__text {
  line-height: 1.5;
}

.lp-block-columns-icon-title__icon {
  height: 50px;
  width: 78px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lp-block-columns-icon-title__icon svg {
  font-size: 50px;
}
.lp-block-columns-icon-title__icon img {
  vertical-align: middle;
}

.lp-block-columns-one-icons__list {
  display: table;
}
.lp-block-columns-one-icons__item {
  display: table-row;
}
.lp-block-columns-one-icons__item:first-child__picture, .lp-block-columns-one-icons__item:first-child__text {
  padding-top: 0;
}
.lp-block-columns-one-icons__picture, .lp-block-columns-one-icons__text {
  display: table-cell;
  vertical-align: top;
  padding-top: 20px;
}
.lp-block-columns-one-icons__picture {
  padding-right: 16px;
  width: 32px;
  font-size: 50px;
}
.lp-block-columns-one-icons__text {
  text-align: left;
}

.lp-block-video-columns__item,
.lp-block-columns-three__item {
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 64em) {
  .lp-block-columns-two__item {
    padding: 0 24px;
  }
  .lp-block-columns-two__inner {
    margin-left: -23px;
    margin-right: -23px;
  }
  .lp-block-columns-header {
    margin-bottom: 48px;
  }
  .lp-block-video-columns,
  .lp-block-columns-with-picture,
  .lp-block-columns-three {
    /* &__row {
        margin-left: -23px; // 8px(.col-*) + 15px (.lp-block-columns-three__item)
        margin-right: -23px; // 8px(.col-*) + 15px (.lp-block-columns-three__item)
    } */
  }
  .lp-block-video-columns__header,
  .lp-block-columns-with-picture__header,
  .lp-block-columns-three__header {
    font-size: 22px;
    margin-bottom: 20px;
  }
  .lp-block-video-columns__item,
  .lp-block-columns-with-picture__item,
  .lp-block-columns-three__item {
    padding-left: 10px;
    padding-right: 10px;
  }
  .lp-block-video-columns__icon,
  .lp-block-columns-with-picture__icon,
  .lp-block-columns-three__icon {
    margin-bottom: 40px;
  }
  /* .lp-block-columns-four {
      &__item {
          margin-bottom: 0;
      }
  } */
  .lp-block-columns-faq__header {
    font-size: 24px;
  }
  .lp-block-columns-one-icons__picture, .lp-block-columns-one-icons__text {
    padding-top: 40px;
  }
  .lp-block-columns-one-icons__picture {
    padding-right: 32px;
    width: 64px;
  }
  .lp-block-video-columns__item {
    margin-bottom: 40px;
  }
}
@media (min-width: 75em) {
  .lp-block-columns-two__item {
    padding-left: 24px;
    padding-right: 24px;
  }
  .lp-block-columns-header {
    margin-bottom: 64px;
  }
  .lp-block-video-columns__header,
  .lp-block-columns-with-picture__header,
  .lp-block-columns-three__header {
    font-size: 24px;
  }
}
.lp-block-columns-picture-with-text__text-block {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  z-index: 10;
}

a.lp-block-columns-picture-with-text__text-block {
  color: #fff;
}
a.lp-block-columns-picture-with-text__text-block:hover {
  color: #fff;
}

.lp-block-columns-picture-with-text__icon {
  font-size: 60px;
}

.lp-block-columns-picture-with-text__inner {
  position: relative;
  overflow: hidden;
  padding-top: 8px;
  padding-bottom: 8px;
}

.lp-block-columns-picture-with-text__img {
  vertical-align: middle;
  width: 100%;
  height: 100%;
}

.lp-block-columns-picture-with-text__image-wrap {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.lp-block-columns-picture-with-text__subtitle,
.lp-block-columns-picture-with-text__text {
  margin-top: 10px;
}

.lp-block-columns-picture-with-text__bg {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: transform 1s;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}

.lp-block-columns-picture-with-text__effect-zoom:hover .lp-block-columns-picture-with-text__bg {
  transform: scale(1.3);
}

.lp-block-columns-three-icons__row {
  margin-left: -10px;
  margin-right: -10px;
}

/* columns-text-picture */
.columns-text-picture__picture {
  margin-bottom: 32px;
}
.columns-text-picture__item {
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}
.columns-text-picture picture-loader-trigger span,
.columns-text-picture picture-loader-trigger {
  display: block;
}
.columns-text-picture__header {
  margin-bottom: 16px;
  font-weight: 600;
}
.columns-text-picture__footer {
  margin-top: 30px;
}
.columns-text-picture__button {
  margin-bottom: 25px;
}

.columns-menu {
  /* &__list-link > a,
  &__header {
      text-decoration: none;

      &:hover {
          text-decoration: underline;
      }
  } */
}
.columns-menu__item {
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  margin-bottom: 50px;
}
.columns-menu__item:last-child {
  margin-bottom: 0;
}
.columns-menu__picture {
  margin-bottom: 32px;
}
.columns-menu__header {
  font-weight: 600;
  margin-bottom: 15px;
  display: inline-block;
}
.columns-menu__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.columns-menu__list-link {
  margin-bottom: 10px;
}