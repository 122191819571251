@charset "UTF-8";
/* >480 - смартфоны */
/* >768 - планшеты */
/* >1024 - нетбуки, ноутбуки */
/* >1200 - десктоп */
/* >1600 - десктоп */
.text-align-left-xs {
  text-align: left;
}

.text-align-center-xs {
  text-align: center;
}

.text-align-right-xs {
  text-align: right;
}

@media (min-width: 48em) {
  .text-align-left-sm {
    text-align: left;
  }
  .text-align-center-sm {
    text-align: center;
  }
  .text-align-right-sm {
    text-align: right;
  }
}
@media (min-width: 64em) {
  .text-align-left-md {
    text-align: left;
  }
  .text-align-center-md {
    text-align: center;
  }
  .text-align-right-md {
    text-align: right;
  }
}
@media (min-width: 75em) {
  .text-align-left-lg {
    text-align: left;
  }
  .text-align-center-lg {
    text-align: center;
  }
  .text-align-right-lg {
    text-align: right;
  }
}