@charset "UTF-8";
/* >480 - смартфоны */
/* >768 - планшеты */
/* >1024 - нетбуки, ноутбуки */
/* >1200 - десктоп */
/* >1600 - десктоп */
.lp-block-exit-popup-buttons__header .lp-h2--single,
.lp-block-exit-popup-form__header .lp-h2--single,
.lp-block-exit-popup-buttons-countdown__header .lp-h2--single {
  margin-bottom: 40px;
}
.lp-block-exit-popup-buttons__footer,
.lp-block-exit-popup-form__footer,
.lp-block-exit-popup-buttons-countdown__footer {
  margin-top: 40px;
}
.lp-block-exit-popup-buttons__footer .lp-btn,
.lp-block-exit-popup-form__footer .lp-btn,
.lp-block-exit-popup-buttons-countdown__footer .lp-btn {
  margin: 0 10px;
}

.lp-block-exit-popup-form-picture__figure {
  padding-right: 8px;
  padding-left: 8px;
}

.lp-block-exit-modal {
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
}

.lp-block-exit-popup-form-picture-modal {
  width: 1120px;
  padding: 14px;
}

.lp-block-exit-popup-buttons-countdown-modal {
  width: 786px;
  padding-left: 0;
  padding-right: 0;
}

.lp-block-exit-modal.lp-block-exit-popup-buttons-countdown-modal {
  padding-left: 0;
  padding-right: 0;
}

.lp-block-exit-popup-buttons-modal {
  width: 882px;
  padding-left: 128px;
  padding-right: 128px;
}

.lp-block-exit-popup-form-modal {
  width: 980px;
  padding-left: 128px;
  padding-right: 128px;
}

@media (min-width: 48em) {
  .lp-block-exit-modal.lp-block-exit-popup-buttons-countdown-modal {
    padding-left: 54px;
    padding-right: 54px;
  }
}