@charset "UTF-8";
/* >480 - смартфоны */
/* >768 - планшеты */
/* >1024 - нетбуки, ноутбуки */
/* >1200 - десктоп */
/* >1600 - десктоп */
@media (min-width: 48em) {
  .modal-iframe-modal .modal-content {
    padding: 0;
  }
  .modal-iframe-modal .adv-modal-close {
    right: 0;
    top: -34px;
  }
  .modal-iframe-modal .adv-modal-close::after {
    content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4gICAgPHBhdGggZmlsbD0iI2ZmZmZmZiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTMuMTMyIDEyLjQyNWw2LjcxNyA2LjcxNy0uNzA3LjcwNy02LjcxNy02LjcxNy02LjcxOCA2LjcxN0w1IDE5LjE0Mmw2LjcxOC02LjcxN0w1IDUuNzA3IDUuNzA3IDVsNi43MTggNi43MThMMTkuMTQyIDVsLjcwNy43MDctNi43MTcgNi43MTh6Ii8+PC9zdmc+") !important;
  }
}