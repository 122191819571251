.lp-block-delimiter-line-horizontal__element {
  height: 1px;
  line-height: 0;
  font-size: 0;
}

.lp-block-delimiter-line-vertical__element {
  height: 40px;
  width: 1px;
  line-height: 0;
  font-size: 0;
  margin: 0 auto;
}