@charset "UTF-8";
/* >480 - смартфоны */
/* >768 - планшеты */
/* >1024 - нетбуки, ноутбуки */
/* >1200 - десктоп */
/* >1600 - десктоп */
.lp-footer-simple__text,
.lp-footer-social__text {
  margin-top: 4px;
  margin-bottom: 4px;
  line-height: 1.5;
}

.lp-footer-social__phone {
  font-size: 22px;
}

.lp-footer-social__email,
.lp-footer-social__address,
.lp-footer-social__copyright {
  font-size: 16px;
}

.lp-footer-social__social-items {
  margin-top: 24px;
  margin-bottom: 24px;
}

.lp-footer-social-simple a.social {
  height: 45px;
  width: 45px;
  font-size: 25px;
}

@media (min-width: 64em) {
  .lp-footer-social__social-items {
    margin-top: 0;
    margin-bottom: 0;
  }
  .lp-footer-social__copyright {
    margin-right: 28px;
  }
}
.lp-footer-with-menu .lp-menu-header__item {
  padding-left: 0 !important;
}

.lp-footer-with-menu__logo,
.lp-footer-with-menu__block-text,
.lp-footer-with-menu__social {
  margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media (min-width: 64em) {
  .lp-footer-with-menu__logo,
  .lp-footer-with-menu__block-text,
  .lp-footer-with-menu__social {
    margin-bottom: 0;
  }
}