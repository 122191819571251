.lp-properties {
  padding: 0;
  margin: 0;
  list-style: none;
  display: table;
  font-size: 18px;
  width: 100%;
}

.lp-properties-row {
  padding: 0;
  margin: 0;
  list-style: none;
  display: table-row;
}

.lp-properties-name,
.lp-properties-value {
  padding: 17px 0 9px;
  display: table-cell;
  border-bottom: 1px solid;
}

.lp-properties-name {
  font-weight: bold;
  padding-right: 15px;
}