@charset "UTF-8";
/* >480 - смартфоны */
/* >768 - планшеты */
/* >1024 - нетбуки, ноутбуки */
/* >1200 - десктоп */
/* >1600 - десктоп */
.lp-countdown {
  text-align: center;
  width: 100%;
  white-space: nowrap;
  font-size: 0;
  line-height: 0;
  transform: translate3d(0, 0, 0);
  padding: 8px;
  padding-left: 0;
  padding-right: 0;
}

.lp-countdown__item,
.lp-countdown__item-part,
.lp-countdown__spliter {
  display: inline-block;
  vertical-align: top;
}

.wSeconds .lp-countdown__item.lp-coutdown__item--three-numbers {
  width: 87px;
}
.wSeconds .lp-countdown__item {
  width: 56px;
  margin-left: 23px;
}
.wSeconds .lp-countdown__item:first-child {
  margin-left: 0;
}
.wSeconds .lp-countdown__item:last-child {
  display: none;
}

/* .lp-countdown__item--indentations {
    margin-left: 23px;
    margin-right: 23px;
} */
.lp-countdown__spliter {
  width: 2%;
  line-height: normal;
  padding: 0 rem-calc(4px) 0 rem-calc(1px);
}

.lp-countdown__item-part {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: bold;
  border-radius: 7.5px;
  background-image: linear-gradient(to bottom, #d6d6d6, #c6c6c6 54%, #878787 54%, #ddd 54%, #d0d0d0 93%, #cbcbcb);
  width: 27px;
  height: 64px;
  text-align: center;
  line-height: normal;
  margin-left: 2px;
  margin-bottom: 12px;
}
.lp-countdown__item-part:first-child {
  margin-left: 0;
}

.color-scheme--dark .lp-countdown__item-part {
  color: #000;
}

.color-scheme--medium .lp-countdown__item-part,
.color-scheme--light .lp-countdown__item-part {
  color: #fff;
  background-image: linear-gradient(to bottom, #323232, #363636 23%, #000 52%, #161616 54%, #3a3a3a 54%, #121212 93%, #000);
}

.lp-countdown__item-label {
  text-align: center;
  font-size: 16px;
  line-height: 1;
  font-weight: 400;
}

.lp-countdown-simple__item {
  position: relative;
  font-size: 0;
  width: 68px;
  text-align: center;
}
.lp-countdown-simple__item:last-child::after {
  display: none;
}
.lp-countdown-simple__item::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  opacity: 0.15;
  background-color: currentcolor;
  width: 1px;
}
.lp-countdown-simple__item-part {
  font-size: 38px;
  font-weight: 600;
  line-height: 1.15;
  margin-bottom: 8px;
}
.lp-countdown-simple__item-label {
  opacity: 0.4;
  font-size: 16px;
  font-weight: normal;
  line-height: 1;
}

@media (min-width: 48em) {
  .wSeconds .lp-countdown__item.lp-coutdown__item--three-numbers {
    width: 174px;
  }
  .wSeconds .lp-countdown__item {
    width: 112px;
    margin-left: 32px;
  }
  .wSeconds .lp-countdown__item:last-child {
    display: inline-block;
  }
  .lp-countdown__item-part {
    font-size: 64px;
    width: 52px;
    height: 84px;
    margin-left: 8px;
  }
  .lp-countdown__item-label {
    font-size: 24px;
  }
  /* .lp-countdown__item--indentations {
      margin-left: 32px;
      margin-right: 32px;
  } */
  .lp-countdown-simple__item {
    width: 146px;
  }
  .lp-countdown-simple__item-part {
    font-size: 52px;
    font-weight: 600;
    line-height: 1.15;
  }
  .lp-countdown-simple__item-label {
    font-size: 24px;
    font-weight: normal;
    line-height: 1;
  }
}
@media (min-width: 75em) {
  .wSeconds .lp-countdown__item.lp-coutdown__item--three-numbers {
    width: 174px;
  }
  .wSeconds .lp-countdown__item {
    width: 112px;
  }
  .lp-countdown__item-part {
    width: 52px;
  }
}